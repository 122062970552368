<template>
  <div class="fire-message" v-if="fireData.length">
    <p>灾情信息</p>
    <div class="table-body">
      <table class="table">
        <tr v-for="(item, index) in fireData" :key="index">
          <td :class="{ current: current === index }">{{ item.buildId > 0 ? item.modelKey || '室内' : '室外' }}</td>
          <td :class="{ current: current === index }">{{ item.direction || '发生火灾' }}</td>
          <div class="icon-bottom" :class="current === index ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" @click.stop="clickTab(item, index)" />
        </tr>
      </table>
    </div>
    <ul class="message" v-if="current !== null">
      <li v-for="(item, index) in list" :key="index">
        <h4>{{ item.title }}</h4>
        ：
        <p>{{ item.content || '无' }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { disaster } from '@/api/sandTable'
export default {
  data() {
    return {
      current: null,
      fireData: [],
      currentData: {},
      list: []
    }
  },

  created() {
    disaster
      .get({
        sandTableId: this.$route.query.sandTableId,
        status: 0
      })
      .then((data) => {
        this.fireData = Array.isArray(data) ? data : []
        if (this.fireData.length) {
          this.clickTab(this.fireData[0], 0)
        }
      })
  },

  methods: {
    clickTab(item, index) {
      this.current = this.current === index ? null : index
      this.currentData = item
      this.joinData(item)
    },

    joinData(data) {
      const { time, area, humanTraffic, reason, creep, peopleTrappedIn, risk } = JSON.parse(data.param).describeTip
      this.list = [
        { title: '时间', content: time },
        { title: '面积', content: area },
        { title: '人流量', content: humanTraffic },
        { title: '原因', content: reason },
        { title: '蔓延', content: creep },
        { title: '受困人员', content: peopleTrappedIn },
        { title: '危险性', content: risk },
        { title: '描述', content: data.description }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/views/checkPlan/common.scss';
.fire-message {
  @include position(absolute, 48px, 0);
  width: 282px;
  z-index: 2;
  background-color: rgba(6, 17, 33, 0.95);
  color: #fff;
  padding: 10px 8px 22px 10px;
  box-sizing: border-box;
  font-size: 14px;
  .table-body {
    margin-top: 12px;
    @include scrollY();
    max-height: 250px;
    .table {
      .current {
        background-color: rgba(11, 69, 225, 0.2);
      }
      tr {
        td {
          width: 150px;
          text-align: center;
          height: 27px;
          border: 1px solid rgba(53, 172, 244, 0.51);
          &:first-child {
            width: 86px;
          }
        }
        .icon-bottom {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: 1px solid #18cffd;
          transform: translateY(5px);
          margin-left: 10px;
          color: #18cffd;
          font-size: 12px;
          @include flex(center);
          cursor: pointer;
        }
      }
    }
  }
  .message {
    @include scrollY();
    max-height: 300px;
    li {
      display: flex;
      padding-left: 13px;
      margin-top: 15px;
      color: #b6b7b9;
      h4 {
        display: inline-block;
        width: 4em;
        text-align: justify;
        text-align-last: justify;
        font-weight: bold;
      }
      p {
        flex: 1;
        margin-left: 5px;
      }
    }
  }
}
</style>
