<template>
  <div class="whiteboard-record arrow_box">
    <template v-if="recordData.length">
      <p>标绘记录</p>

      <ul class="whiteboard-record-lists">
        <li v-for="item in recordData" :key="item.id">
          <el-image fit="cover" :src="item.imageUrl" :preview-src-list="recordData.map(item => item.imageUrl)"></el-image>
          <p>{{ item.gmtCreate | formatTime }}</p>
        </li>
      </ul>
    </template>

    <el-empty v-else :image-size="60" description="暂无标绘记录" />
  </div>
</template>

<script>
import api from '@/api/sandTable'
import { formatLocalDate } from '@/utils/util'

export default {
  data() {
    return {
      recordData: []
    }
  },

  created() {
    this.$$event = [['refresh.whiteboard.record', this.fetchWiteboardRecord.bind(this)]]

    this.$$event.forEach(item => this.$EventBus.$on(...item))

    this.fetchWiteboardRecord()
  },

  beforeDestroy() {
    this.$$event.forEach(item => this.$EventBus.$off(...item))
  },

  methods: {
    fetchWiteboardRecord() {
      api.whiteBoard
        .get({
          sandTableId: this.$route.query.sandTableId
        })
        .then((data) => {
          this.recordData = data
        })
    }
  },

  filters: {
    formatTime(time) {
      if (!time) return ''
      return formatLocalDate(time, 'Y-M-D h:m:s')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.whiteboard-record {
  @include size(340px, calc(100vh - 60px));
  padding: 18px;
  box-sizing: border-box;

  background-color: #061121de;
  position: absolute;
  top: 60px;
  right: 53px;
  z-index: 3;

  & > p {
    color: #fff;
    font-size: var(--zk-size);
    margin-bottom: 16px;
  }

  &-lists {
    max-height: calc(100% - 30px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    li {
      padding: 8px;
      @include size(137px, 110px);
      box-sizing: border-box;
      background-color: #0a2543;
      border-radius: 2px;
      margin-bottom: 8px;
      cursor: pointer;

      &:not(:nth-child(2n)) {
        margin-right: 8px;
      }

      .el-image {
        @include size(121px, 73px);
      }

      p {
        color: #fff;
        font-size: var(--zk-min-size);
        text-align: center;
        margin-top: 5px;
      }
    }
  }
}

.arrow_box:after {
  content: '';
  @include size(0);
  position: absolute;
  right: 30%;
  bottom: 100%;

  pointer-events: none;
  border: solid transparent;
  border-color: rgba(6, 17, 33, 0);
  border-bottom-color: #061121;
  border-width: 10px;
  margin-left: -10px;
}
</style>
