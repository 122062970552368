<template>
  <ul class="equip-container">
    <li v-for="item in MENU_DATA" :key="item.id" @click="$emit('tap', item)">
      <p :style="{ backgroundImage: 'url(' + item.icon + ')' }"></p>
      <span>{{ item.name }}</span>
    </li>
  </ul>
</template>

<script>
const TASK_ID = 4

const MENU_DATA = [
  {
    id: 5,
    taskId: TASK_ID,
    name: '供水路线',
    icon: 'https://fastdfs.equiplink.cn/group1/M00/00/12/rBKWal_lYdKAPN45AAABlpK4884127.png?filename=%E7%96%8F%E6%95%A3%E8%B7%AF%E7%BA%BF%402x.png'
  },
  {
    id: 6,
    taskId: TASK_ID,
    name: '作战区',
    icon: 'https://fastdfs.equiplink.cn/group1/M01/00/15/wKgAW2CKYl2AQAjAAAABBPAJG2Y543.png?filename=%E8%BD%A6%E8%BE%86%E5%81%9C%E9%9D%A0%E5%8C%BA.png'
  },
  {
    id: 7,
    taskId: TASK_ID,
    name: '集结待命区',
    icon: 'https://fastdfs.equiplink.cn/group1/M00/00/15/wKgAW2CKYl2AYKyLAAAD25IVuq4469.png?filename=%E9%9B%86%E7%BB%93%E5%8C%BA.png'
  },
  {
    id: 8,
    taskId: TASK_ID,
    name: '待命区',
    icon: 'https://fastdfs.equiplink.cn/group1/M02/00/15/wKgAo2CKYl2AS5A1AAADxjhOc38573.png?filename=%E5%BE%85%E5%91%BD%E5%8C%BA.png'
  },
  {
    id: 9,
    taskId: TASK_ID,
    name: '指挥中心',
    icon: 'https://fastdfs.equiplink.cn/group1/M01/00/15/wKgAo2CKYl2APTgeAAAGCwdeq_0219.png?filename=%E6%8C%87%E6%8C%A5%E4%B8%AD%E5%BF%83.png'
  },
  {
    id: 10,
    taskId: TASK_ID,
    name: '供水保障区',
    icon: 'https://fastdfs.equiplink.cn/group1/M02/00/15/wKgAW2CKYl2AdvGRAAABUvcgVnA640.png?filename=%E4%BE%9B%E6%B0%B4%E4%BF%9D%E9%9A%9C%E5%8C%BA.png'
  },
  {
    id: 11,
    taskId: TASK_ID,
    name: '后勤保障区',
    icon: 'https://fastdfs.equiplink.cn/group1/M00/00/15/wKgAo2CKYl2AVcYtAAADYsMY-jI851.png?filename=%E5%90%8E%E5%8B%A4%E4%BF%9D%E9%9A%9C%E5%8C%BA.png'
  }
]

export default {
  data() {
    return {
      MENU_DATA
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.equip-container {
  @include flex-center();
  flex-wrap: wrap;
  li {
    color: #fff;
    @include size(132px);
    @include flex-center(center, column);
    background-color: #0a2543;
    font-size: var(--zk-min-size);
    margin-bottom: 18px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    &:not(:nth-child(2n)) {
      margin-right: 18px;
    }
    p {
      @include size(48px);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 23px;
    }
  }
}
</style>
