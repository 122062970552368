<template>
  <ul class="equip-container">
    <li v-for="item in MENU_DATA" :key="item.id" @click="$emit('tap', item)">
      <p :style="{ backgroundImage: 'url(' + item.icon + ')' }"></p>
      <span>{{ item.name }}</span>
    </li>
  </ul>
</template>

<script>
const TASK_ID = 3

const MENU_DATA = [
  {
    id: 2,
    taskId: TASK_ID,
    name: '移动水炮',
    icon: 'https://fastdfs.equiplink.cn/group1/M00/00/00/rBKWa1_lYdGANSZqAAAFDqXkI58081.png?filename=%E4%BF%9D%E5%8D%AB%E7%82%B9%402x.png'
  },
  {
    id: 3,
    taskId: TASK_ID,
    name: '移动泡沫炮',
    icon: 'https://fastdfs.equiplink.cn/group1/M00/00/00/rBKWa1_lYdGANSZqAAAFDqXkI58081.png?filename=%E4%BF%9D%E5%8D%AB%E7%82%B9%402x.png'
  },
  {
    id: 4,
    taskId: TASK_ID,
    name: '消防坦克',
    icon: 'https://fastdfs.equiplink.cn/group1/M00/00/00/rBKWa1_lYdGANSZqAAAFDqXkI58081.png?filename=%E4%BF%9D%E5%8D%AB%E7%82%B9%402x.png'
  }
]

export default {
  data() {
    return {
      MENU_DATA
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.equip-container {
  @include flex-center();
  flex-wrap: wrap;
  li {
    color: #fff;
    @include size(132px);
    @include flex-center(center, column);
    background-color: #0a2543;
    font-size: var(--zk-min-size);
    margin-bottom: 18px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    &:not(:nth-child(2n)) {
      margin-right: 18px;
    }
    p {
      @include size(48px);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 23px;
    }
  }
}
</style>
