<template>
  <zk-dialog v-if="isShown" hide-footer title="历史推演" width="1340px" height="800px" @close="toggleDialogStatus(false)">
    <ul v-if="sandTableHistory.length" class="deduction-lists">
      <li class="deduction-lists_item" v-for="item in sandTableHistory" :key="item.id">
        <el-image fit="cover" :src="item.coverUrl" lazy></el-image>
        <p class="title">{{ item.name }}</p>
        <p class="time">{{ item.time }}</p>

        <div class="menu">
          <el-button @click="editSandTable(item)">继续推演</el-button>
          <el-button @click="checkSandTable(item)">查看</el-button>
        </div>
      </li>
    </ul>
  </zk-dialog>
</template>

<script>
import api from '@/api/sandTable'
import { formatLocalDate } from '@/utils/util'

export default {
  data() {
    return {
      isShown: false,

      sandTableHistory: []
    }
  },

  created() {
    this.fetchHistoryList()

    this.$$event = [['refresh.sandTable.history', this.fetchHistoryList.bind(this)]]

    this.$$event.forEach(item => this.$EventBus.$on(...item))
  },

  beforeDestroy() {
    this.$$event.forEach(item => this.$EventBus.$off(...item))
  },

  methods: {
    fetchHistoryList() {
      api.record.get().then((data) => {
        data.forEach((item) => {
          item.time = formatLocalDate(item.gmtUpdated, 'Y-M-D h:m')
        })
        this.sandTableHistory = data
      })
    },

    editSandTable(data) {
      const loading = this.$loading({
        lock: true,
        text: '获取单位数据...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$router.push({
        name: 'Operate',
        query: { id: data.unitId, sandTableId: data.id }
      })

      setTimeout(() => {
        loading.close()
      }, 1200)
    },

    checkSandTable(data) {
      const { id, unitId, name } = data
      this.$router.push({ name: 'CheckPlan', query: { id, unitId, name } })
    },

    toggleDialogStatus(status) {
      status = status === void 0 ? !this.isShown : status

      this.isShown = status
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.deduction-lists {
  @include size(100%, 650px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  &_item {
    @include size(295px, 286px);
    box-sizing: border-box;
    padding: 20px;
    background-color: #122e4d;
    border-radius: 2px;
    margin-bottom: 24px;
    color: #fff;
    position: relative;
    &:not(:nth-child(4n)) {
      margin-right: 25px;
    }

    .el-image {
      @include size(100%, 195px);
    }

    .title {
      margin-top: 18px;
      font-size: var(--zk-size);
    }

    .time {
      opacity: 0.5;
      margin-top: 5px;
      font-size: var(--zk-min-size);
    }

    .menu {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: #000000, $alpha: 0.5);
      @include flex-center(center);
      transition: 0.25s;
      opacity: 0;
      z-index: -1;
      .el-button {
        @include size(60px, 20px);
        font-size: var(--zk-min-size);
        background-color: var(--zk-color);
        color: #fff;
        line-height: 20px;
        padding: 0;
        border: none;
        border-radius: 2px;
        transition: transform 0.25s ease;
        &:hover {
          transform: scale(1.05);
        }
      }
    }

    &:hover {
      border: 1px solid var(--zk-color);

      .menu {
        opacity: 1;
        z-index: 1;
      }
    }
  }
}
</style>
