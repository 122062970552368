<template>
  <div class="angle-picker" :class="[theme]">
    <div class="circle" ref="circle" @click="onClickCircle">
      <div class="c-pointer" @mousedown="mousedownHandle" :style="{ transform: `rotate(${closeWise ? angle : -angle}deg)` }">
        <div class="c-center"></div>
        <div class="c-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAngle } from './angle'

export default {
  name: 'angle-picker',

  props: {
    // 风格
    theme: {
      type: String,
      default: ''
    },
    // 顺时针还是逆时针
    closeWise: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      angle: 0
    }
  },

  methods: {
    getCenterPos() {
      let domRect = this.$refs.circle.getBoundingClientRect()
      return {
        x: domRect.width / 2 + domRect.x,
        y: domRect.height / 2 + domRect.y
      }
    },

    onClickCircle(e) {
      const centerPos = this.getCenterPos()
      let angle = getAngle(centerPos.x, centerPos.y, e.clientX, e.clientY, this.closeWise)
      this.angle = angle

      this.$emit('change', angle)
    },

    mousedownHandle() {
      const centerPos = this.getCenterPos()

      console.log(centerPos)

      let mouseMove = (e) => {
        let angle = getAngle(centerPos.x, centerPos.y, e.clientX, e.clientY, this.closeWise)
        this.angle = angle
        this.$emit('change', angle)
      }

      let mouseUp = () => {
        document.body.removeEventListener('mousemove', mouseMove)
        document.body.removeEventListener('mouseup', mouseUp)
      }

      document.body.addEventListener('mousemove', mouseMove)
      document.body.addEventListener('mouseup', mouseUp)
    },

    setAngle(angle) {
      this.angle = angle || 0
    }
  }
}
</script>

<style scoped lang="scss">
.angle-picker {
  width: 50px;
  text-align: center;
}

.circle {
  width: 101px;
  height: 101px;
  border-radius: 50%;
  border: 1px solid #1077ba;
  // background: radial-gradient(#dbdbdb, #e1e1de, #f8f8f3);
  background-color: #011226;
  position: relative;
  z-index: 3;
}

.c-center {
  height: 4px;
  width: 4px;
  position: absolute;
  background: #838383;
  border-radius: 50%;
  opacity: 0;
}

.c-pointer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  margin: -2px 0 0 -2px;
  /*围绕一点旋转*/
  transform-origin: 2px 2px;
}

.c-line {
  position: relative;
  margin-top: 1.5px;
  margin-right: -2px;
  height: 3px;
  background: #18cffd;
  border-radius: 50%;
}

.theme2 {
  .c-center {
    display: none;
  }
  .c-line {
    height: 0;
    &:after {
      content: '';
      width: 10px;
      height: 10px;
      display: block;
      background: #29aadf;
      position: absolute;
      left: 100%;
      margin-left: -5px;
      border-radius: 50%;
    }
  }
}
</style>
