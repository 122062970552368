import Inside from './Inside'
import Outside from './Outside'
import Store from '@/store'

export default class Common {
  inside = null
  outside = null

  constructor(scope) {
    this._scope = scope
    this.inside = new Inside(scope)
    this.outside = new Outside(scope)
  }

  ready() {
    this.outside.listenMouseEvent()
  }

  destroy() {
    this.inside.destroy()
    this.outside.destroy()
  }

  isInside() {
    return this._scope.isInside()
  }

  startDraw(data) {
    if (Store.state.planDrill.enterInside) {
      this.inside.startDraw(data)
    } else {
      this.outside.startDraw(data)
    }
  }

  clearEntity() {
    this.inside.clearEntity()
    this.outside.clearEntity()
  }

  renderDrawData(data) {
    this.inside.renderDrawData(data)
    this.outside.renderDrawData(data)
  }

  removeDataByGrade(grade) {
    // 暂时注释，目前没有内功部署
    // this.inside.removeDataByGrade(grade)
    this.outside.removeDataByGrade(grade)
  }
}
