<template>
  <div class="right-menu_content">
    <template v-if="Object.keys(hasSecondTeamStand).length > 0">
      <ul v-for="item in hasSecondTeamStand" :key="item.id">
        <p>{{ item.organName }}</p>
        <template v-if="item.cars.length > 0">
          <!-- <li v-for="car in item.cars" :key="car.carId" @click="selectedCar(car)">
            <el-image fit="cover" :src="car.carCoverUrl"></el-image>
            <p>{{ car.carName }}</p>

            <p v-if="car.deployState == 1" class="already-checkbox">已放置</p>
          </li> -->

          <el-tooltip v-for="car in item.cars" :key="car.carId" effect="dark" :content="car.carName" placement="left">
            <li :class="{ active: car.carId === currentSelectCar }" @click="selectedCar(car)">
              <el-image fit="cover" :src="car.carCoverUrl"></el-image>
              <p>{{ car.carName }}</p>

              <p v-if="car.deployState == 1" class="already-checkbox">已放置</p>
            </li>
          </el-tooltip>
        </template>

        <el-empty v-else :image="require('@/assets/images/empty.png')" :image-size="60" description="暂无车辆信息" />
      </ul>
    </template>

    <el-empty v-else :image-size="60" description="请先进行力量调派">
      <el-button type="zk-primary" @click="powerTransfer">力量调派</el-button>
    </el-empty>
  </div>
</template>

<script>
import api from '@/api/sandTable'

export default {
  name: 'Power',

  data() {
    return {
      currentSelectCar: '',
      hasSecondTeamStand: {}
    }
  },

  created() {
    this.fetchSandTableTeamStand()
  },

  methods: {
    fetchSandTableTeamStand() {
      return api.deploy
        .getCar({
          sandTableId: this.$route.query.sandTableId,
          status: 0
        })
        .then((data) => {
          const result = {}
          data.forEach((item) => {
            if (!result[item.carOrganId]) {
              result[item.carOrganId] = {
                cars: [],
                id: item.carId,
                organName: item.carOrganName
              }
            }

            result[item.carOrganId].cars.push(item)
          })

          this.hasSecondTeamStand = result
        })
    },

    selectedCar(data) {
      if (+data.deployState === 1) {
        this.$emit('focus', data)
      } else {
        this.currentSelectCar = data.carId
        this.$emit('tap', data)
      }
    },

    powerTransfer() {
      this.$EventBus.$emit('power.for.powerTransfer', 1)
    },

    resetStatus() {
      this.currentSelectCar = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.right-menu_content {
  height: calc(100vh - 150px);
  overflow-y: auto;

  ul {
    min-width: 282px;
    margin-top: 16px;
    padding: 12px 16px 16px;
    box-sizing: border-box;
    background-color: #0a2543;
    border-radius: 2px;
    @include flex-center();
    flex-wrap: wrap;
    position: relative;

    & > p {
      color: #fff;
      flex: 1 0 100%;
      margin-bottom: 11px;
    }

    & > .el-button {
      position: absolute;
      top: 12px;
      right: 16px;
    }

    &::v-deep .el-empty {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 0;
      text-align: center;
    }

    li {
      padding: 8px;
      border-radius: 2px;
      background-color: #122e4d;
      @include flex-center(center, column);

      color: #fff;
      font-size: var(--zk-min-size);
      margin-bottom: 8px;

      cursor: pointer;
      user-select: none;
      transition: all 0.2s;

      position: relative;

      p {
        max-width: 102px;
        @include text-ellipsis(1);
      }

      &.active {
        color: var(--zk-text);
        border: 1px solid var(--zk-color);
      }

      &:not(:nth-of-type(2n)) {
        margin-right: 8px;
      }

      .el-image {
        @include size(105px, 60px);
        margin-bottom: 5px;
      }

      .already-checkbox {
        @include size(105px, 60px);
        position: absolute;
        top: 8px;
        left: 8px;
        color: #c1cee1;
        background-color: rgba($color: #000000, $alpha: 0.5);
        text-align: center;
        line-height: 60px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &::v-deep .el-empty__description p {
    color: #fff;
  }
}
</style>
