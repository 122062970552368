<template>
  <div class="deploy-right-menu" v-show="!enterInside">
    <div class="right-menu menu-box">
      <el-tabs v-model="currentTabName" @tab-click="changeTabs">
        <el-tab-pane v-for="item in tabs" :key="item.name" :label="item.label" :name="item.name" stretch>
          <component ref="menuCom" :is="item.name" @tap="selectMenu" @focus="focusModel"></component>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 针对车辆编辑的弹窗 -->
    <transition name="el-fade-in-linear">
      <zk-dialog
        v-if="isShownCarDetailModel"
        ref="dialog"
        width="470px"
        :title="`${carDetail.organName}：${carDetail.carName}`"
        :btnTitle="carDetailEdit ? '确认' : '编辑'"
        :showCancel="carDetailEdit"
        @close="onCloseCarDetail"
        @submit="onEditCarDetail"
        class="car-detail-model"
      >
        <div>
          <template v-if="carDetailEdit">
            <div class="disater-description">
              <label>{{ TASK_TIP.label }}</label>
              <ul>
                <li v-for="option in TASK_TIP.options" :key="option" :class="{ active: tipSelected.includes(option) }" @click="selectTip(option)">{{ option }}</li>
              </ul>
            </div>

            <div class="disater-description">
              <label>描述</label>
              <el-input v-model="description" type="textarea" placeholder="请输入描述" :autosize="{ minRows: 3, maxRows: 3 }"></el-input>
            </div>

            <div class="disater-description">
              <label>操作</label>
              <operation ref="operation" />
            </div>

            <div class="delete-car" @click="deleteCar">
              <i class="el-icon-delete"></i>
              <span>删除</span>
            </div>
          </template>

          <template v-else>
            <div class="disater-description">
              <label>{{ TASK_TIP.label }}</label>
              <ul>
                <li v-for="option in carDetail.describeTip" :key="option" class="active">{{ option }}</li>
              </ul>
            </div>

            <div class="disater-description">
              <label>描述</label>
              <el-input v-model="carDetail.description" type="textarea" placeholder="暂无描述" :autosize="{ minRows: 3, maxRows: 3 }" disabled></el-input>
            </div>

            <div class="car-equip-table">
              <label>车载装备信息</label>
              <el-table :data="carEquipTable" height="200" empty-text="暂无车载装备数据" style="width: 100%">
                <el-table-column prop="name" align="center" label="装备名称" />
                <el-table-column prop="count" align="center" label="数量" />
              </el-table>
            </div>
          </template>
        </div>
      </zk-dialog>
    </transition>

    <!-- 车辆新增弹窗 -->
    <transition name="el-fade-in-linear">
      <zk-dialog v-if="isShownCarDescModel" ref="dialog" :title="`${lastSuccessData.organName}：${lastSuccessData.carName}`" width="470px" @close="onCloseCarDetail" @submit="onSubmit">
        <div>
          <div class="disater-description">
            <label>{{ TASK_TIP.label }}</label>
            <ul>
              <li v-for="option in TASK_TIP.options" :key="option" :class="{ active: tipSelected.includes(option) }" @click="selectTip(option)">{{ option }}</li>
            </ul>
          </div>

          <div class="disater-description">
            <label>描述</label>
            <el-input v-model="description" type="textarea" placeholder="请输入描述" :autosize="{ minRows: 3, maxRows: 3 }"></el-input>
          </div>
        </div>
      </zk-dialog>
    </transition>

    <!-- 普通描述框 -->
    <transition name="el-fade-in-linear">
      <zk-dialog v-if="isShownDescription" ref="dialog" title="描述" width="470px" @close="onCloseDescription" @submit="onSubmit">
        <div class="disater-description">
          <label>描述</label>
          <el-input v-model="description" type="textarea" placeholder="请输入描述" :autosize="{ minRows: 3, maxRows: 3 }"></el-input>
        </div>
      </zk-dialog>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api/sandTable'

import Power from './MenuItem/Power'
import Equip from './MenuItem/Equip'
import Instruction from './MenuItem/Instruction'
import Whiteboard from './MenuItem/Whiteboard'
import Operation from './components/Operation'
const TABS = [
  { label: '作战力量', name: 'power' },
  { label: '作战装备', name: 'equip' },
  { label: '作战指令', name: 'instruction' }
]

const TASK_TIP = {
  key: 'task',
  label: '执行任务',
  options: ['取水', '灭火', '连接水泵接合器', '登高', '高喷', '洗消']
}

export default {
  components: {
    Power,
    Equip,
    Instruction,
    Whiteboard,
    Operation
  },

  data() {
    return {
      tabs: TABS,
      currentTabName: TABS[0].name,

      TASK_TIP,
      tipSelected: [],
      description: '',

      lastSuccessData: {},
      isShownCarDescModel: false,
      isShownDescription: false,

      carDetail: {},
      carDetailEdit: false,
      isShownCarDetailModel: false,

      carEquipTable: []
    }
  },

  created() {
    this.$$event = [['draw.cancel.callback.outside', () => this.resetStatus()]]

    this.$$event.forEach(item => this.$EventBus.$on(...item))
  },

  beforeDestroy() {
    this.$$event.forEach(item => this.$EventBus.$off(...item))
  },

  computed: {
    sandTableId() {
      return this.$route.query.sandTableId
    },
    ...mapState('planDrill', ['enterInside'])
  },

  methods: {
    // 根据车辆id获取车载装备
    fetchCarEquip(carId) {
      return api.powerTransfer
        .getCarEquip({
          carId,
          sandTableId: this.sandTableId
        })
        .then((data) => {
          this.carEquipTable = data
        })
    },

    changeTabs({ name }) {
      this.$emit('change', name)
    },

    // 选择提示信息
    selectTip(option) {
      if (this.tipSelected.includes(option)) {
        this.tipSelected = this.tipSelected.filter(item => item !== option)
      } else {
        this.tipSelected.push(option)
      }
    },

    // 绘制工具选择
    selectMenu(data) {
      this.$parent.startDraw(data)
    },

    focusModel({ organId, carId }) {
      this.$parent.__Car.flyToCar(organId, carId)
    },

    // 标绘结束显示弹窗
    popupConfirm(data) {
      if (data.onlyPoint) {
        this.onlyGetPoint(data)
        return
      }

      this.lastSuccessData = data
      if (data.location) {
        this.description = `地址：${data.location}`
      }

      if (this.currentTabName === TABS[0].name) {
        this.isShownCarDescModel = true
      } else {
        this.isShownDescription = true
      }
    },

    //只获取点的位置,不进行绘制
    onlyGetPoint(data) {
      this.waterGunData = this.$parent.__Car.openWaterGun(data)
    },

    // 点击车辆，显示车辆详情弹窗
    popupCarModel(data) {
      if (this.carDetail.id) {
        this.onCloseCarDetail()
        this.carDetail = {}
      }

      if (data.operationJson) {
        const json = JSON.parse(data.operationJson)
        data.describeTip = json.describeTip
      }
      this.carDetail = data

      this.fetchCarEquip(data.carId).then(() => {
        this.isShownCarDetailModel = true
      })
    },

    onCloseCarDetail() {
      this.$refs.operation?.changeRotate()

      this.isShownCarDetailModel = false
      this.isShownCarDescModel = false
      this.carDetail = {}
      this.carDetailEdit = false
      this.resetStatus()
      this.$parent.__Car.removeOverlay()
    },

    onCloseCarDescModel() {
      this.isShownCarDescModel = false
      this.resetStatus()
    },

    onCloseDescription() {
      this.isShownDescription = false
      this.resetStatus()
    },

    resetStatus() {
      this.description = ''
      this.tipSelected = []
      this.$refs.menuCom.forEach(com => com.resetStatus && com.resetStatus())
    },

    onEditCarDetail() {
      if (!this.carDetailEdit) {
        this.carDetailEdit = true
        this.description = this.carDetail.description
        this.tipSelected = this.carDetail.describeTip

        //回显消防车旋转方向
        this.$nextTick(() => {
          const { operationJson } = this.$store.state.exterior.entityData
          this.$refs.operation.setAngle(JSON.parse(operationJson)?.options?.rotate)
        })
      } else {
        this.onSubmitCar()
      }
    },

    // 修改车辆信息
    // options 是其他替换信息
    onSubmitCar() {
      this.$refs.dialog.showLoading()
      const data = this.carDetail

      const rotate = this.$refs.operation.getRotate()

      const operationJson = JSON.parse(data.operationJson)
      operationJson.options = {
        ...operationJson.options,
        rotate: rotate === undefined ? operationJson.options?.rotate : rotate || 0,
        waterGunData: this.waterGunData
      }

      api.deploy
        .add({
          id: data.id,
          carId: data.carId,
          grade: data.grade,
          position: data.position,
          latitude: data.latitude,
          longitude: data.longitude,
          sandTableId: data.sandTableId,
          description: this.description,
          operationJson: JSON.stringify(operationJson)
        })
        .then((data) => {
          this.$message.success('设置成功')

          this.onCloseCarDetail()
          this.$parent.__Car.removeEntity(data.carId)

          this.$parent.__Car.renderDrawDataItem(data)
          this.updatePower()
        })
        .finally(() => {
          this.$refs.dialog && this.$refs.dialog.hideLoading()
        })
    },

    // 删除车辆
    deleteCar() {
      const data = this.carDetail

      this.$confirm('确定删除该车辆？', '提示').then(() => {
        api.deploy
          .delete({
            id: data.id
          })
          .then(() => {
            this.$message.success('删除成功')
            this.onCloseCarDetail()
            this.$parent.__Car.removeEntity(data.carId)
            this.updatePower()
          })
      })
    },

    // 新增车辆或其他类型
    onSubmit() {
      const { carId, latitude, longitude, positions, id } = this.lastSuccessData
      this.$refs.dialog.showLoading()

      let data = {
        grade: this.$parent.$refs.topStage.currentGrade,
        sandTableId: this.sandTableId,
        description: this.description,
        latitude,
        longitude,
        // 坐标点
        position: JSON.stringify(positions)
      }

      if (carId) {
        data.carId = carId
        // 额外选的参数
        data.operationJson = JSON.stringify({
          describeTip: this.tipSelected
        })
      } else {
        data.deployTypeId = id
      }

      api.deploy
        .add(data)
        .then((data) => {
          this.$message.success('设置成功')

          if (carId) {
            this.onCloseCarDescModel()
            this.$parent.__Car.renderDrawDataItem(data)
            this.updatePower()
          } else {
            this.onCloseDescription()
            this.$parent.__DeployCommon.outside.renderDrawDataItem(data)
          }
        })
        .finally(() => {
          this.$refs.dialog && this.$refs.dialog.hideLoading()
        })
    },

    // 更新右侧车辆列表
    updatePower() {
      if (this.currentTabName === TABS[0].name) {
        this.$refs.menuCom.forEach(com => com.fetchSandTableTeamStand && com.fetchSandTableTeamStand())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../common';
@import '~@/assets/styles/mixins';

.right-menu {
  width: 325px;
  right: 49px;
  padding: 16px;
  box-sizing: border-box;
  font-size: var(--font-size);
  align-items: flex-start;
  z-index: 3;

  &::v-deep .el-tabs {
    width: 100%;
    &__item {
      color: #fff;
      padding: 0 10px;
      &.is-active {
        color: var(--zk-text);
        font-weight: 700;
      }
    }
    .el-tabs__content {
      overflow: unset;
    }

    &__active-bar {
      background-color: var(--zk-color);
    }

    .el-tabs__nav-wrap::after {
      background-color: #061121;
    }
  }
}

.car-detail-model {
  position: static;
  background-color: transparent;
  &::v-deep .pop-body {
    right: 374px;
    bottom: 1px;
    top: unset;
    left: unset;
    transform: unset;
    z-index: 4;
  }
}

.disater-description {
  display: flex;
  margin-bottom: 20px;
  label {
    flex: 0 0 60px;
    margin-right: 22px;
    font-weight: 700;
    color: #fff;
    text-align: right;
    margin-top: 8px;
  }

  ul {
    @include flex-center();
    flex-wrap: wrap;
    li {
      color: #fff;
      padding: 6px 10px;
      min-height: 22px;
      line-height: 22px;
      border-radius: 2px;
      font-size: var(--zk-size);
      background-color: rgba(76, 89, 108, 0.63);
      margin-bottom: 8px;
      user-select: none;
      cursor: pointer;
      transition: all 0.2s;
      &:not(:last-child) {
        margin-right: 8px;
      }
      &.active {
        background-color: var(--zk-color);
      }
    }
  }

  &::v-deep .el-textarea__inner {
    padding: 10px 15px;
  }
}

.car-equip-table {
  margin-top: 30px;
  label {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }

  &::v-deep .el-table {
    color: #fff;
    margin-top: 10px;
    background-color: transparent;
    &::before {
      content: unset;
    }
    th {
      border: none;
      color: #fff;
      background-color: #0c1f3a;
    }
    tr {
      background-color: transparent;
      td {
        border: none;
        padding: 5px 0;
      }
      &:hover > td {
        background: rgba(0, 77, 155, 0.66);
      }
    }
    .el-table__empty-text {
      color: #fff;
    }
  }
}

.delete-car {
  @include flex-center(center);
  position: absolute;
  left: 0;
  bottom: -30px;

  font-size: 14px;
  color: #00c6ff;
  cursor: pointer;
}
</style>
