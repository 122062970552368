<template>
  <!-- 车辆导航面板 -->
  <ul
    v-if="visibleFlag"
    class="operation-panel el-dialog custom-dialog"
    :style="{
      top: clientY ? clientY + 'px' : '50%',
      left: clientX ? clientX + 'px' : '50%'
    }"
  >
    <div class="operation-panel-title">
      <p class="head-style">
        <span class="head-bottom-style"></span>
        <span class="title">操作面板</span>
        <i class="el-icon-close" @click="closePanelFn"></i>
      </p>
    </div>

    <template v-for="item in navList">
      <li :key="item.key" @click="navClickFn(item)" :class="{ isActive: currentNavKey === item.key }">
        <i :class="['iconfont ', 'icon' + item.icon]"></i>
        {{ item.name }}
      </li>
    </template>
    <el-input
      v-model="operationItem.description"
      class="operation-panel-title operation-panel-description"
      type="textarea"
      maxlength="1000"
      show-word-limit
      :autosize="{ minRows: 4, maxRows: 6 }"
      placeholder="请输入详细描述"
      clearable
    ></el-input>
  </ul>
</template>

<script>
import HtmlHostMsg from 'HtmlHostMsg'
import { deepClone } from '@/utils/util'
import { mapState } from 'vuex'
import modelApi from '@/api/model'

const NAV_LISTS = [
  { key: 'del', icon: 'DEL', name: '删除' },
  { key: 'correct', icon: 'correct', name: '确认' }
]

export default {
  name: 'OperationPanel',

  data() {
    return {
      visibleFlag: false,
      navList: deepClone(NAV_LISTS),
      currentNavKey: null,
      clientY: 0,
      clientX: 0,
      firstWatchItem: false,
      operationItem: {
        description: ''
      },
      fireEquipToolList: []
    }
  },

  computed: {
    ...mapState('planDrill', ['linkWaterData'])
  },

  watch: {
    linkWaterData: {
      immediate: false,
      handler(val) {
        if (val) {
          this.onSetData(val)
        }
      }
    }
  },

  methods: {
    showPanel(data) {
      this.visibleFlag = true

      this.$set(this, 'operationItem', data)
      this.onSetData(data)
    },

    hidePanel() {
      this.visibleFlag = false
    },

    // 设置数据
    onSetData(val) {
      const {
        clientY,
        clientX,
        // key,
        config,
        linkWater,
        draw,
        operationJson,
        kid,
        description,
        panel, //控制面板的值
        buildId
      } = val

      this.clientY = clientY
      this.clientX = clientX

      if (config && config.create && config.create.config) delete val.config.create.config
      this.operationItem = {
        ...val,
        currentBuildId: buildId,
        position: val.position,
        description: description && description !== undefined ? description : ''
      }

      if (!draw && !linkWater) {
        /* 区分不同操作面板 */
        if (panel) {
          let panelJson = JSON.parse(panel)
          this.switchNavFn(panelJson)
        } else {
          this.switchNavFn()
        }
      } else if (linkWater) {
        this.closePanelFn()
        this.$confirm('确定删除该水带?', '提示').then(() => {
          let index = operationJson.linkWater.findIndex(v => v.id === kid)
          if (index >= 0) {
            val.operationJson.linkWater.splice(index, 1)
            this.operationItem = { ...val }
            this.onSaveOperate()
            HtmlHostMsg.event('linkWater', { remove: true, kid })
          }
        })
      }
    },

    closePanelFn() {
      HtmlHostMsg.event('tool', { clear: true, draw: true })
      this.visibleFlag = false
    },

    navClickFn(item) {
      this.currentNavKey = item.key
      switch (this.currentNavKey) {
        case 'translate':
        case 'rotate':
        case 'scale':
          this.historyName = this.operationItem.name = `消防员${item.name}`
          HtmlHostMsg.event('tool', {
            axis: true,
            update: true,
            mode: item.key,
            ...this.operationItem
          })
          break
        case 'fireExting':
          this.historyName = this.operationItem.name = `消防员${item.name}`
          this.fireExtinguishingAgentFn()
          break
        case 'linkWater':
          this.historyName = this.operationItem.name = `消防员${item.name}`
          this.linkWaterFn()
          break
        case 'del':
          this.delPowerDeployFn()
          break
        case 'correct':
          this.operationItem.name = this.historyName || '消防员'
          this.onSaveOperate()
          break
      }
    },

    // 喷灭火剂
    fireExtinguishingAgentFn() {
      HtmlHostMsg.event('fireExting', { ...this.operationItem })
    },

    switchNavFn(json) {
      this.navList = deepClone(NAV_LISTS)
      if (json) this.navList.splice(0, 0, ...json)
    },

    /* 三维 */
    // 渲染列表视图
    renderStereo(data) {
      return data.map((item) => {
        let { config, unitDraw } = item
        if (config && typeof config === 'string') {
          item.config = JSON.parse(config)
        }
        item.position = unitDraw?.position
        item.key = '消防设施'
        if (item.config?.uiText) item.uiText = item.name
        return item
      })
    },

    async linkWaterFn() {
      let item = this.operationItem
      let fireList =
        await modelApi.getUnitDrawList({
          unitId: this.$route.query.id,
          buildId: item.buildId,
          drawType: 1,
          scene: 1
        }) || []

      /* 筛选出当前楼的消防设施 */
      let data = fireList && fireList.length ? fireList.filter(v => v.drawName && v.drawName.indexOf('消火栓') > 0 && v.buildId === item.buildId) : []
      // if (data && data.length) data = this.renderStereo(data)

      HtmlHostMsg.event('tool', data)
      HtmlHostMsg.event('linkWater', { ...this.operationItem, draw: true })
    },

    delPowerDeployFn() {
      this.$confirm('确定删除该数据？', '提示').then(() => {
        this.closePanelFn()
        this.$emit('delete', this.operationItem)
      })
    },

    onSaveOperate() {
      let { position, operationJson } = this.operationItem
      if (position && typeof position === 'object') {
        this.operationItem.position = JSON.stringify(position)
      }
      if (operationJson && typeof operationJson === 'object') {
        this.operationItem.operationJson = JSON.stringify(operationJson)
      }
      this.operationItem.points = position

      this.$emit('update', this.operationItem)
      this.closePanelFn()
    }
  }
}
</script>

<style lang="scss">
.operation-panel {
  position: absolute;
  z-index: 2;
  // background: $background;
  border-radius: 2px;
  // color: $color;
  min-width: 12.5rem;
  width: auto;
  transform: translate(50%, -100%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .operation-panel-title {
    padding: 0 1.125rem;
    grid-column-start: span 3;
    text-align: center;
    position: relative;
    cursor: pointer;
    left: 50%;
    /* width: auto; */
    transform: translateX(-50%);
  }

  li {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 0.875rem;
    padding: 0.3125rem;
    margin: 0.3125rem;
    box-sizing: border-box;

    .iconfont {
      display: block;
    }

    &.isActive {
      border: 1px solid rgba(11, 69, 225, 0.2);
      box-shadow: 0px 2px 23px 4px rgba(11, 69, 225, 0.2);
    }

    &:hover,
    &.isActive {
      color: #fff;
    }
  }

  .operation-panel-description {
    padding: 1.125rem;
    box-sizing: border-box;

    /deep/ .el-input__count {
      bottom: 1.5rem;
      right: 2.375rem;
    }
  }
}
</style>
