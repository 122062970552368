import HtmlHostMsg from 'HtmlHostMsg'

import Mixin from './Mixin'
import Store from '@/store'
import EventBus from '@/utils/bus'
/**
 * 内景公共处理方法
 * @description
 */
export default class Inside extends Mixin {
  catchDrawData = []

  constructor(scope) {
    super()
    this._scope = scope

    this.initEvent()
  }

  destroy() {
    this.clearStorage()

    this.removeEvent && this.removeEvent()
  }

  initEvent() {
    this.$events = [['draw.tool.callback.inside', this.shuntInsideToolData.bind(this)]]

    this.$events.forEach(item => EventBus.$on(...item))

    this.removeEvent = () => {
      this.$events.forEach(item => EventBus.$off(...item))
    }
  }

  async startDraw(drawData) {
    // 获取对应的配置表
    const data = await this.fetchDrawConfByTaskId(drawData)

    let { name, config, ...params } = data

    if (config && typeof config === 'string') {
      config = JSON.parse(config)
    }

    HtmlHostMsg.event('tool', {
      config,
      draw: true,
      key: name,
      deployTypeId: drawData.id,
      ...params
    })
  }

  shuntInsideToolData(data) {
    console.log(data.isview)
    if (data.isview) return

    if (data.drag) {
      this._scope.$refs.operationPanel.showPanel({ ...data, isOperation: true })
    } else if (data.draw) {
      this.successDraw(data)
    }
  }

  successDraw(data) {
    this._scope.popupConfirm(data)
  }

  clearEntity() {
    const data = this.catchDrawData
    let keys = []
    data.forEach((item) => {
      keys = item.map(_item => _item.key)
    })

    keys = new Set([...keys])

    keys.forEach((key) => {
      HtmlHostMsg.event('tool', {
        key,
        clear: true,
        draw: true
      })
    })
  }

  renderDrawData(data) {
    const currentBuildId = Store.state.planDrill.moduleBuildId

    data = data.filter(i => i.rootBuildId === currentBuildId)
    data.forEach((item) => {
      let { deployType, config, description } = item

      if (deployType) {
        item.key = deployType.name
      }

      if (item.draw) delete item.draw

      if (config && typeof config === 'string') {
        item.config = JSON.parse(config)
      }

      item.description = description
    })
    this.catchDrawData.push(data)

    HtmlHostMsg.event('tool', data)
  }

  // 根据grade 删除比传入grade要小的值
  removeDataByGrade(grade) {
    const data = this.catchDrawData.filter((item) => {
      return item.grade > grade
    })

    this.clearEntity()
    data.forEach(item => HtmlHostMsg.event('tool', item))
  }
}
