<template>
  <div class="img-draw">
    <div class="d-body">
      <div class="board">
        <canvas id="ctxFront" ref="ctxFront" touch-action="auto" @touchstart="handlerTouchstart" @touchmove="handlerTouchmove" @touchend="handlerTouchend"></canvas>
        <canvas id="ctxBase" ref="ctxBase" style="touch-action=auto'"></canvas>
        <canvas id="ctxBack" ref="ctxBack" style="touch-action=auto'"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
let sx, sy, mx, my

/**
 * 带背景图白板
 * @description
 * 支持
 *  - 画笔绘制
 *  - 绘制直线
 *  - 绘制圆
 *  - 绘制矩形
 *  - 擦除
 *
 * @TODO 重构
 */

// 画笔
const TOOL_PEN = 'pen'
// 线
const TOOL_LINE = 'line'
// 圆
const TOOL_CIRCLE = 'circle'
// 矩形
const TOOL_RECTANGLE = 'rectangle'
// 橡皮擦
const TOOL_ERASER = 'eraser'

// 默认画笔粗细
const DEFAULT_SLIDE = 5

// 画笔默认颜色
const DEFAULT_COLOR = '#d22a2a'

export default {
  props: {
    offsettop: {
      type: Number,
      default: 0
    }
  },

  created() {
    this.activeTool = TOOL_PEN

    this.canvasFront = null
    this.canvasBack = null
    this.canvasBase = null
    this.ctxBase = null
    this.ctxFront = null
    this.ctxBack = null

    this.slide = DEFAULT_SLIDE

    this.canDraw = false

    this.currentImg = {
      url: '',
      width: '',
      height: '',
      scale: 1,
      index: 0
    }

    this.prevDis = true
    this.nextDis = true
    this.baseMap = ''

    // 画笔颜色
    this.brushColor = DEFAULT_COLOR
  },

  methods: {
    // 设置底图，并开始绘图
    setBackgroundImage(url) {
      this.currentImg.url = url
      this.baseMap = url

      this.handleInitCanvas()
    },

    // 设置画笔颜色
    setBrushColor(color = DEFAULT_COLOR) {
      this.brushColor = color
    },

    // 设置画板工具
    setCanvasTool(key) {
      this.activeTool = key
      this.handleDrawCanvas(key)
    },

    // 设置画笔粗细
    setPenSlide(slide = DEFAULT_SLIDE) {
      this.slide = slide
    },

    // 清除除背景图外的元素
    clearCanvas() {
      this.handleInitCanvas()
    },

    /** 上一步*/
    undo() {
      if (this.currentImg.index > 0) {
        this.nextDis = false
        this.currentImg.index -= 1
        this.currentImg.index === 0 ? this.prevDis = true : this.prevDis = false
        this.currentImg.url = this.canvasStore[this.currentImg.index]
        this.currentImg.scale = 1
        this.handleDrawImage()
      } else {
        this.prevDis = true
      }
    },

    /** 下一步*/
    redo() {
      if (this.currentImg.index < this.canvasStore.length - 1) {
        this.prevDis = false
        this.currentImg.index += 1
        this.currentImg.index === this.canvasStore.length - 1 ? this.nextDis = true : this.nextDis = false
        this.currentImg.url = this.canvasStore[this.currentImg.index]
        this.currentImg.scale = 1
        this.handleDrawImage()
      } else {
        this.nextDis = true
      }
    },

    /** 初始化画布*/
    handleInitCanvas() {
      this.currentImg = {
        ...this.currentImg,
        width: '',
        height: '',
        scale: 1,
        index: 0
      }
      this.canvasStore = [this.currentImg.url]

      this.prevDis = true
      this.nextDis = true

      // 用于绘制的画板
      this.canvasFront = document.getElementById('ctxFront')
      // 用于生成绘制后图片的画板
      this.canvasBack = document.getElementById('ctxBack')
      // 底图画板，橡皮擦除时获取像素放到绘制画板中，达到不擦出底图的效果
      this.canvasBase = document.getElementById('ctxBase')

      this.ctxBase = this.canvasBase.getContext('2d')
      this.ctxFront = this.canvasFront.getContext('2d')
      this.ctxBack = this.canvasBack.getContext('2d')
      this.ctxFront.strokeStyle = this.brushColor
      let img = new Image()
      img.src = this.baseMap
      img.setAttribute('crossOrigin', '')
      img.onload = () => {
        let width = parseInt(img.width)
        let height = parseInt(img.height)
        this.currentImg.width = width
        this.currentImg.height = height
        this.canvasFront.width = width
        this.canvasFront.height = height
        this.canvasBack.width = width
        this.canvasBack.height = height
        this.canvasBase.width = width
        this.canvasBase.height = height
        // this.ctxFront.drawImage(this, 0, 0, width, height)
        this.ctxBack.drawImage(img, 0, 0, width, height)
        this.ctxBase.drawImage(img, 0, 0, width, height)

        this.$emit('load')
      }
    },

    /** 处理放大缩小*/
    handleDrawImage() {
      const img = new Image()
      const baseImg = new Image()
      img.setAttribute('crossOrigin', '')
      baseImg.setAttribute('crossOrigin', '')

      const { url, width, height, scale } = this.currentImg

      img.src = url
      baseImg.src = this.baseMap

      const actualWidth = width * scale
      const actualHeight = height * scale

      this.currentImg.width = actualWidth
      this.currentImg.height = actualHeight

      const baseOption = [0, 0, actualWidth, actualHeight]

      img.onload = () => {
        this.canvasFront.width = actualWidth
        this.canvasFront.height = actualHeight
        this.canvasBack.width = actualWidth
        this.canvasBack.height = actualHeight
        this.ctxFront.drawImage(img, ...baseOption)
        this.ctxBack.drawImage(img, ...baseOption)
      }

      baseImg.onload = () => {
        this.canvasBase.width = actualWidth
        this.canvasBase.height = actualHeight
        this.ctxBase.drawImage(baseImg, ...baseOption)
      }
    },

    handleBeLarge() {
      this.currentImg.scale = 1
      this.currentImg.scale += 0.1
      this.$nextTick(() => {
        this.handleDrawImage()
      })
    },

    handleBeSmall() {
      this.currentImg.scale = 1
      this.currentImg.scale -= 0.1
      this.$nextTick(() => {
        this.handleDrawImage()
      })
    },

    /** 下载图片*/
    exportImage(qualityOf = 0.8) {
      return new Promise((resolve, reject) => {
        if (!this.currentImg.url) {
          return reject(new Error('我要导出什么？'))
        }

        let canvas = document.getElementById('ctxBack')
        resolve(canvas.toDataURL('image/webp', qualityOf))
      })
    },

    handleFrommatCanvas() {
      this.ctxFront.clearRect(0, 0, this.canvasFront.width, this.canvasFront.height)
    },

    handlerTouchstart(e) {
      this.ctxFront.strokeStyle = this.brushColor
      this.ctxFront.lineWidth = this.slide
      e = e || window.event
      if (e.changedTouches) {
        e.clientX = e.changedTouches[0].clientX
        e.clientY = e.changedTouches[0].clientY
        let vertex = this.getVertexPosition(this.ctxFront.canvas)
        e.offsetX = e.changedTouches[0].pageX - vertex.left
        e.offsetY = e.changedTouches[0].pageY - vertex.top
      }
      sx = e.clientX - this.canvasFront.offsetLeft
      sy = e.clientY - this.canvasFront.offsetTop - this.offsettop
      const cbx = this.ctxBase.getImageData(e.offsetX - this.slide / 2, e.offsetY - this.slide / 2, this.slide * 2, this.slide * 2)
      this.ctxFront.moveTo(sx, sy)
      this.canDraw = true

      switch (this.activeTool) {
        case TOOL_PEN:
          this.ctxFront.beginPath()
          break
        case TOOL_ERASER:
          this.ctxFront.putImageData(cbx, e.offsetX - this.slide / 2, e.offsetY - this.slide / 2)
          break
      }
    },

    handlerTouchmove(e) {
      e = e || window.event
      if (e.changedTouches) {
        e.clientX = e.changedTouches[0].clientX
        e.clientY = e.changedTouches[0].clientY
        let vertex = this.getVertexPosition(this.ctxFront.canvas)
        e.offsetX = e.changedTouches[0].pageX - vertex.left
        e.offsetY = e.changedTouches[0].pageY - vertex.top
      }
      mx = e.clientX - this.canvasFront.offsetLeft
      my = e.clientY - this.canvasFront.offsetTop - this.offsettop
      const cbx = this.ctxBase.getImageData(e.offsetX - this.slide / 2, e.offsetY - this.slide / 2, this.slide * 2, this.slide * 2)
      if (this.canDraw) {
        const draw = {
          [TOOL_PEN]: () => {
            this.ctxFront.lineTo(mx, my)
            this.ctxFront.stroke()
          },
          [TOOL_LINE]: () => {
            this.handleFrommatCanvas()
            this.ctxFront.beginPath()
            this.ctxFront.moveTo(sx, sy)
            this.ctxFront.lineTo(mx, my)
            this.ctxFront.stroke()
          },
          [TOOL_CIRCLE]: () => {
            this.handleFrommatCanvas()
            this.ctxFront.beginPath()
            // eslint-disable-next-line no-case-declarations
            let rds = Math.sqrt((sx - 10 - mx) * (sx - 10 - mx) + (sy - 49 - my) * (sy - 49 - my))
            this.ctxFront.arc(sx - 15, sy - 69, rds, 0, Math.PI * 2, false)
            this.ctxFront.stroke()
          },
          [TOOL_RECTANGLE]: () => {
            this.handleFrommatCanvas()
            this.ctxFront.beginPath()
            this.ctxFront.moveTo(sx, sy)
            this.ctxFront.lineTo(mx, sy)
            this.ctxFront.lineTo(mx, my)
            this.ctxFront.lineTo(sx, my)
            this.ctxFront.lineTo(sx, sy)
            this.ctxFront.stroke()
          },
          [TOOL_ERASER]: () => {
            this.ctxFront.putImageData(cbx, e.offsetX - this.slide / 2, e.offsetY - this.slide / 2)
          }
        }

        draw[this.activeTool] && draw[this.activeTool]()
      }
    },

    handlerTouchend() {
      if (this.canDraw) {
        this.canDraw = false
        this.ctxFront.closePath()
        this.handleSaveCanvasStore()
      }
    },

    handleDrawCanvas(type) {
      this.canDraw = false
      let sx, sy, mx, my
      //鼠标按下
      let mousedown = (e) => {
        this.ctxFront.strokeStyle = this.brushColor
        this.ctxFront.lineWidth = this.slide
        e = e || window.event
        if (e.changedTouches) {
          e.clientX = e.changedTouches[0].clientX
          e.clientY = e.changedTouches[0].clientY
          let vertex = this.getVertexPosition(this.ctxFront.canvas)
          e.offsetX = e.changedTouches[0].pageX - vertex.left
          e.offsetY = e.changedTouches[0].pageY - vertex.top
        }
        sx = e.clientX - this.canvasFront.offsetLeft
        sy = e.clientY - this.canvasFront.offsetTop - this.offsettop
        const cbx = this.ctxBase.getImageData(e.offsetX - this.slide / 2, e.offsetY - this.slide / 2, this.slide * 2, this.slide * 2)
        this.ctxFront.moveTo(sx, sy)
        this.canDraw = true
        switch (type) {
          case TOOL_PEN:
            this.ctxFront.beginPath()
            break
          case TOOL_ERASER:
            this.ctxFront.putImageData(cbx, e.offsetX - this.slide / 2, e.offsetY - this.slide / 2)
            break
        }
      }

      let mousemove = (e) => {
        e = e || window.event
        if (e.changedTouches) {
          e.clientX = e.changedTouches[0].clientX
          e.clientY = e.changedTouches[0].clientY
          let vertex = this.getVertexPosition(this.ctxFront.canvas)
          e.offsetX = e.changedTouches[0].pageX - vertex.left
          e.offsetY = e.changedTouches[0].pageY - vertex.top
        }
        mx = e.clientX - this.canvasFront.offsetLeft
        my = e.clientY - this.canvasFront.offsetTop - this.offsettop
        const cbx = this.ctxBase.getImageData(e.offsetX - this.slide / 2, e.offsetY - this.slide / 2, this.slide * 2, this.slide * 2)
        if (this.canDraw) {
          const draw = {
            [TOOL_PEN]: () => {
              this.ctxFront.lineTo(mx, my)
              this.ctxFront.stroke()
            },
            [TOOL_LINE]: () => {
              this.handleFrommatCanvas()
              this.ctxFront.beginPath()
              this.ctxFront.moveTo(sx, sy)
              this.ctxFront.lineTo(mx, my)
              this.ctxFront.stroke()
            },
            [TOOL_CIRCLE]: () => {
              this.handleFrommatCanvas()
              this.ctxFront.beginPath()
              // eslint-disable-next-line no-case-declarations
              let rds = Math.sqrt((sx - 10 - mx) * (sx - 10 - mx) + (sy - 49 - my) * (sy - 49 - my))
              this.ctxFront.arc(sx - 15, sy - 69, rds, 0, Math.PI * 2, false)
              this.ctxFront.stroke()
            },
            [TOOL_RECTANGLE]: () => {
              this.handleFrommatCanvas()
              this.ctxFront.beginPath()
              this.ctxFront.moveTo(sx, sy)
              this.ctxFront.lineTo(mx, sy)
              this.ctxFront.lineTo(mx, my)
              this.ctxFront.lineTo(sx, my)
              this.ctxFront.lineTo(sx, sy)
              this.ctxFront.stroke()
            },
            [TOOL_ERASER]: () => {
              this.ctxFront.putImageData(cbx, e.offsetX - this.slide / 2, e.offsetY - this.slide / 2)
            }
          }

          draw[type] && draw[type]()
        }
      }
      let mouseup = () => {
        if (this.canDraw) {
          this.canDraw = false
          this.ctxFront.closePath()
          this.handleSaveCanvasStore()
        }
      }
      this.canvasFront.onmousedown = e => mousedown(e)
      this.canvasFront.onmousemove = e => mousemove(e)
      this.canvasFront.onmouseup = e => mouseup(e)
      this.canvasFront.onmouseout = e => mouseup(e)
      this.canvasFront.onmouseleave = e => mouseup(e)
    },

    getVertexPosition(el) {
      let currentTarget = el
      let top = 0
      let left = 0
      while (currentTarget !== null) {
        top += currentTarget.offsetTop
        left += currentTarget.offsetLeft
        currentTarget = currentTarget.offsetParent
      }
      return { top, left }
    },

    /** 保存绘制*/
    handleSaveCanvasStore() {
      let url = this.canvasFront.toDataURL()
      let image = new Image()
      image.src = url
      image.setAttribute('crossOrigin', '')
      image.onload = () => {
        this.ctxFront.clearRect(0, 0, this.canvasFront.width, this.canvasFront.height)
        this.ctxFront.drawImage(image, 0, 0, image.width, image.height)
        this.ctxBack.drawImage(image, 0, 0, image.width, image.height)
        const url2 = this.canvasBack.toDataURL()

        this.currentImg.url = url2
        this.currentImg.index += 1
        this.canvasStore.push(url2)
        this.prevDis = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.img-draw {
  width: 100%;
  height: 100%;
  min-height: 300px;
  // 父元素记得赋予宽高
  overflow: hidden;
  touch-action: none;

  .d-body {
    position: relative;
    height: 100%;
    .board {
      position: relative;
      min-height: 100%;
      canvas {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 0;
      }
      #ctxFront {
        z-index: 5;
      }
      #ctxBack {
        z-index: 3;
      }
      #ctxBase {
        z-index: 1;
      }
    }
  }
}
</style>
