<template>
  <div>
    <div class="right-menu menu-box">
      <p class="title">力量调派</p>
      <div class="right-menu_content">
        <ul v-for="item in hasSecondTeamStand" :key="item.id">
          <p>{{ item.organName }}</p>
          <el-button v-if="isEdit" type="text" @click="chooseCar(item)">选择车辆</el-button>

          <template v-if="item.cars.length > 0">
            <el-tooltip v-for="car in item.cars" :key="car.id" effect="dark" :content="car.carName" placement="left">
              <li>
                <el-image fit="cover" :src="car.carImageUrl"></el-image>
                <p>{{ car.carName }}</p>
              </li>
            </el-tooltip>
          </template>

          <el-empty v-else :image="require('@/assets/images/empty.png')" :image-size="60" description="暂无车辆信息"></el-empty>
        </ul>
      </div>

      <div class="right-menu_footer">
        <template v-if="isEdit">
          <el-button type="zk-primary" @click="onCancel">取消</el-button>
          <el-button style="margin-left: 36px" type="zk-primary" @click="onSubmit">确定</el-button>
        </template>

        <el-button v-else type="zk-primary" @click="onEdit">编辑</el-button>
      </div>
    </div>

    <zk-dialog v-if="isShownChooseCar" :title="`选择${currentSelectCarOrganName}车辆`" width="460px" @close="onCloseChooseCarDialog" @submit="confirmSelectCar">
      <div class="choose-car-box">
        <el-checkbox-group class="choose-car-lists" v-model="selectedCar">
          <div v-for="item in carOptions" :key="item.id" :class="{ 'checkbox-item': true, 'checkbox-item_active': selectedCar.includes(item.id) }">
            <el-image fit="cover" :src="item.coverUrl"></el-image>
            <p>
              <img :src="require(`@/assets/images/operate/checkbox${selectedCar.includes(item.id) ? '-active' : ''}.png`)" alt="checkbox" />
              <span>{{ item.name }}</span>
            </p>
            <el-checkbox :label="item.id"></el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </zk-dialog>
  </div>
</template>

<script>
import api from '@/api/sandTable'
import { findCarByOrganId } from '@/api/file-info'

export default {
  data() {
    return {
      isEdit: false,
      hasSecondTeamStand: [],

      selectedCar: [],

      carOptions: [],
      currentSelectCarOrganName: '',
      isShownChooseCar: false
    }
  },

  created() {
    this.initFetch()
  },

  computed: {
    sandTableId() {
      return this.$route.query.sandTableId
    }
  },

  methods: {
    initFetch() {
      this.fetchSandTableTeamStand().then((data) => {
        this.$emit('update', data.map(item => item.organId) || [])
      })
    },

    // 获取右侧队站列表
    fetchSandTableTeamStand() {
      return api.powerTransfer
        .getList({
          sandTableId: this.sandTableId,
          status: 0
        })
        .then((data) => {
          this.hasSecondTeamStand = data || []

          return data
        })
    },

    // 根据organId查找对应的id
    findIdByOrganId(id) {
      const result = this.hasSecondTeamStand.find(item => +item.organId === +id) || {}

      return result.id
    },

    async chooseCar(item) {
      this.currentSelectCarOrganId = item.organId
      const data = await findCarByOrganId({ organId: item.organId })

      this.carOptions = data
      this.currentSelectCarOrganName = item.organName
      this.selectedCar = item.cars.map(item => item.carId)
      this.isShownChooseCar = true
    },

    onCloseChooseCarDialog() {
      this.selectedCar = []
      this.isShownChooseCar = false
    },

    confirmSelectCar() {
      return api.powerTransfer
        .addCar({
          sandTableId: this.sandTableId,
          carIds: this.selectedCar,
          organId: this.currentSelectCarOrganId
        })
        .then(() => {
          this.$message.success('选择成功')

          this.fetchSandTableTeamStand()
          this.onCloseChooseCarDialog()
        })
    },

    onEdit() {
      this.isEdit = true
    },

    quitEdit() {
      this.isEdit = false
    },

    onCancel() {
      this.quitEdit()
    },

    onSubmit() {
      this.quitEdit()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../common';
@import '~@/assets/styles/mixins';

.right-menu {
  width: 350px;
  right: 49px;
  padding: 16px;
  box-sizing: border-box;
  font-size: var(--font-size);
  align-items: flex-start;
  * {
    box-sizing: border-box;
  }

  .title {
    color: #fff;
  }

  &_content {
    height: calc(100% - 30px - 50px - 10px);
    overflow-y: auto;
  }

  ul {
    width: 100%;
    min-width: 282px;
    margin-top: 16px;
    padding: 12px 16px 16px;
    background-color: #0a2543;
    border-radius: 2px;
    @include flex-center();
    flex-wrap: wrap;
    position: relative;

    & > p {
      color: #fff;
      flex: 1 0 100%;
      margin-bottom: 11px;
    }

    & > .el-button {
      position: absolute;
      top: 5px;
      right: 16px;
    }

    &::v-deep .el-empty {
      width: 100%;
      padding-top: 20px;
      padding-bottom: 0;
      text-align: center;
      .el-empty__description p {
        color: #fff;
      }
    }

    li {
      @include size(130px, 110px);
      box-sizing: border-box;
      padding: 8px;
      border-radius: 2px;
      background-color: #122e4d;
      @include flex-center(center, column);

      color: #fff;
      font-size: var(--zk-min-size);
      margin-bottom: 8px;

      cursor: pointer;
      user-select: none;
      transition: all 0.2s;

      p {
        max-width: 102px;
        @include text-ellipsis(1);
      }

      &:not(:nth-of-type(2n)) {
        margin-right: 8px;
      }

      .el-image {
        @include size(105px, 60px);
        margin-bottom: 5px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &_footer {
    @include flex-center(center);
    @include size(100%, 51px);
    background-color: #051d3f;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.choose-car-box {
  max-height: 500px;
  overflow-y: auto;
}

.choose-car-lists {
  @include flex-center();
  flex-wrap: wrap;

  .checkbox-item {
    @include size(130px, 110px);
    box-sizing: border-box;
    flex-direction: column;
    @include flex-center(center);
    position: relative;
    margin-bottom: 8px;
    background-color: #122e4d;
    cursor: pointer;
    user-select: none;
    &:not(:nth-of-type(3n)) {
      margin-right: 8px;
    }

    &_active {
      border: 1px solid var(--zk-color);
    }

    .el-image {
      @include size(105px, 61px);
    }

    p {
      color: #fff;
      font-size: var(--zk-min-size);
      @include flex-center(center);
      margin-top: 8px;
      img {
        @include size(13px);
        margin-right: 5px;
      }
    }

    .el-checkbox {
      opacity: 0;
      @include size(100%);
      position: absolute;
    }
  }
}
</style>
