<template>
  <div class="operate-container">
    <top-header />

    <right-tab ref="rightTab" @tab="changeOperateTab" />

    <transition name="el-fade-in-linear">
      <disaster v-if="currentTabIndex === 0" />
      <power-transfer v-else-if="currentTabIndex === 1" />
      <deploy v-else-if="currentTabIndex === 2" />
    </transition>

    <bottom-menu v-show="currentTabIndex !== 1 && !whiteboardSwitch" />

    <!-- 白板 -->
    <whiteboard v-if="whiteboardSwitch" @cancel="whiteboardSwitch = false" />

    <div v-show="!whiteboardSwitch" class="whiteboard-switch" @click="whiteboardSwitch = true">
      <svg t="1629707712556" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4924" width="32" height="32">
        <path
          d="M624.725333 754.901333l-369.493333-213.333333 234.752-406.4a128 128 0 0 1 174.848-46.848l147.712 85.290667a128 128 0 0 1 46.848 174.848l-234.666667 406.442666z m49.92-513.109333a42.666667 42.666667 0 0 0-58.282666 15.616l-128 221.696a42.666667 42.666667 0 1 0 73.898666 42.666667l128-221.696a42.666667 42.666667 0 0 0-15.616-58.282667zM238.08 827.264l147.797333 85.333333-22.186666 38.485334-190.165334-11.989334 64.554667-111.786666z m-4.181333-248.746667l369.493333 213.333334c-17.066667 29.568-41.557333 48.256-73.472 56.106666-31.914667 7.850667-66.645333 20.650667-104.234667 38.4l-184.746666-106.666666c-3.456-41.429333-9.813333-77.952-18.944-109.482667-9.130667-31.573333-5.162667-62.122667 11.904-91.690667z"
          fill="#ffffff"
          p-id="4925"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import ZK from '@gis/zk-base'
import api from '@/api/sandTable'
import modelApi from '@/api/model'
import { uploadFile } from '@/api/upload'

import { dataURLtoFile, uploadPreprocess } from '@/utils/util'
import { PLANDRAWTYPE, SCEN_ARIO, TOOL_COLOR_LIST } from '@/utils/conf'

import TopHeader from './components/TopHeader'
import RightTab from './components/RightTab'

// 灾情设定
import Disaster from './components/Disaster'
// 力量调派
import PowerTransfer from './components/PowerTransfer'
// 力量部署
import Deploy from './components/Deploy'

// 底部菜单
import BottomMenu from '@/components/BottomMenu'

// 白板
import Whiteboard from '../components/WhiteBoard/index'

export default {
  components: {
    TopHeader,
    RightTab,
    Disaster,
    PowerTransfer,
    Deploy,
    BottomMenu,
    Whiteboard
  },

  inject: ['cesiumBox'],

  data() {
    return {
      currentTabIndex: 0,

      // 白板开关
      whiteboardSwitch: false
    }
  },

  computed: {
    unitId() {
      return this.$route.query.id
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchCompanyInfoByUnitId()
    })
  },

  beforeDestroy() {
    this.resetOperate()
  },

  methods: {
    resetOperate() {
      // 移除监听事件
      if (this.offListeners) {
        this.offListeners()
      }

      // 移除3dtile
      this.cesiumBox().removeTilesetLayer()
      // 移除建筑体信息
      this.removeBuilderDrawData()
      // 移除建筑体框
      this.handlerHideLastOverlay()
      // 移除建筑体测量数据
      this.resetMeasureEntity()
      // 清除内景数据
      this.$store.dispatch('planDrill/resetThingModel')
    },

    /**
     * 根据unitId获取单位信息
     * 请求建筑体信息进行加载并渲染在视图上(以供进入内景的入口)
     */
    fetchCompanyInfoByUnitId() {
      modelApi
        .getCompanyInfo({
          unitId: this.unitId
        })
        .then((data) => {
          this.$store.commit('user/SET_CURRENT_COMPANY_INFO', data)
          this.initCesiumByCompanyInfo(data)
        })
    },

    // 根据单位信息初始化cesium
    initCesiumByCompanyInfo(data) {
      // 初始化cesium 3dtile
      this.cesiumBox()
        .init3dTilesetLayer([data.modelUrl])
        .then(() => this.cesiumBox().flyToDefaultPerspective(data.id))
        .then(() => {
          if (this.$route.query.add) {
            setTimeout(() => {
              this.firstAddCoverUrl(data)
            }, 4000)
          }
        })

      // 加载建筑体
      this.fetchBildTreeByBuildId()

      // 订阅事件
      this.listenMouseEvent()
    },

    // 新增推演（初次进入）保存截图作为封面图
    firstAddCoverUrl(data) {
      // 生成md5，base64转file
      // 上传
      return this.cesiumBox()
        .captureScreenshot(0.4)
        .then(base64 => uploadPreprocess(dataURLtoFile(base64)))
        .then(params => uploadFile(params))
        .then((url) => {
          return api.record.add({
            coverUrl: url,
            unitId: data.id,
            id: this.$route.query.sandTableId
          })
        })
    },

    fetchBildTreeByBuildId() {
      return modelApi
        .getUnitDrawList({
          drawType: PLANDRAWTYPE.BUILD,
          scene: SCEN_ARIO.EXTERNAL,
          unitId: this.unitId
        })
        .then((data) => {
          this.handlerBuilderDrawData(data)
          this.handlerGetBuildDrawDataByInside(data)
        })
    },

    // 绘制建筑体信息
    handlerBuilderDrawData(data) {
      // 针对珠海电厂,禁止首次进入绘制建筑
      // if (+this.unitId === 4) return

      const cacheBuildDrawDataLists = this.cacheBuildDrawDataLists
      if (cacheBuildDrawDataLists && cacheBuildDrawDataLists.length > 0) return

      this.cacheBuildDrawData = {}

      data = data.filter(item => !!item.drawTool && !!item.config)

      for (const item of data) {
        //禁止绘制罐体
        if (item.drawToolId === 56) {
          continue
        }
        // 获得配置表
        const config = JSON.parse(item.config)
        const drawConfig = JSON.parse(item.drawTool.config)
        const key = item.id

        // 绘画工具id
        const drawToolId = item.drawTool.id
        // 生成信息弹窗
        const overlay = this.cesiumBox().generateOverlay({ ...item, drawConfig, isViews: true })
        // 获取绘制类
        const entityGenerate = this.cesiumBox().getEntity()

        try {
          const primitive = entityGenerate[drawConfig.child || drawConfig.drawType](`${drawToolId}-${key}`, config.positions, {
            icon: drawConfig.icon,
            label: {
              text: item.drawName
            },
            ...drawConfig.options,
            isBuild: true
          })

          if (!this.cacheBuildDrawData[drawToolId]) {
            this.cacheBuildDrawData[drawToolId] = {}
          }

          // 添加实体
          this.cacheBuildDrawData[drawToolId][key] = {
            primitive,
            overlay,
            data: item,
            measureData: config.measureData
          }

          if (!this.cacheBuildDrawDataLists) this.cacheBuildDrawDataLists = []
          // 用来删除的
          this.cacheBuildDrawDataLists.push({
            primitive,
            overlay
          })
        } catch (error) {
          console.log(error)
        }
      }
    },

    removeBuilderDrawData() {
      const lists = this.cacheBuildDrawDataLists || []
      lists.forEach((item) => {
        item.primitive.remove()
        item.overlay.destroy()
      })
      this.cacheBuildDrawDataLists = []
    },

    // 处理内景
    handlerGetBuildDrawDataByInside(data) {
      // 获取模型地址
      const lists = data.filter(item => item.buildId && item.modelUrl)

      if (!lists.length) return

      // 加载内景并做隐藏处理
      // 默认加载第一个
      const { modelUrl, buildId } = lists[0]
      this.$store.commit('planDrill/SET_PLANDRILL_BUILDID', buildId)
      this.$store.commit('planDrill/SET_PLANDRILL_LOADURL', modelUrl)
    },

    // 监听触控事件
    listenMouseEvent() {
      const viewer = this.cesiumBox().getViewer()
      const listeners = [
        [ZK.MouseEventType.CLICK, this.handlerLeftClick.bind(this)],
        [ZK.MouseEventType.WHEEL, this.handlerHideLastOverlay.bind(this)],
        [ZK.MouseEventType.PINCH_MOVE, this.handlerHideLastOverlay.bind(this)]
      ]

      listeners.forEach(item => viewer.on(...item))

      this.offListeners = () => {
        console.log('销毁订阅事件')
        listeners.forEach(item => viewer.off(...item))
      }
    },

    handlerLeftClick({ target }) {
      this.handlerHideLastOverlay()

      const lastMeasureEntity = this.currentMeasureEntity
      if (lastMeasureEntity) {
        this.resetMeasureEntity(lastMeasureEntity)
        this.currentMeasureEntity = null
      }

      // 绘画时禁止
      if (this.cesiumBox().isDrawing) return

      if (!Cesium.defined(target) || !Cesium.defined(target.id)) return

      const objId = target.id._objId
      this.changeOverlay(objId, true)
      this.changeShownMeasureData(objId)
    },

    changeOverlay(objId, isShown = false) {
      if (!objId) return

      const [root, id] = objId.split('-')

      let vessel = {}

      if (this.cacheBuildDrawData[root] && this.cacheBuildDrawData[root][id]) {
        vessel = this.cacheBuildDrawData[root][id]
      }

      const overlay = vessel.overlay

      if (!overlay) return

      overlay[isShown ? 'show' : 'hide']()

      if (isShown) {
        this.lastOverlayId = objId
      }
    },

    handlerHideLastOverlay() {
      // 建筑体弹窗
      if (this.lastOverlayId) {
        this.changeOverlay(this.lastOverlayId)
        this.lastOverlayId = null
      }
    },

    changeShownMeasureData(objId) {
      if (!objId) return

      const [root, id] = objId.split('-')

      if (!this.cacheBuildDrawData[root]) return
      let vessel = this.cacheBuildDrawData[root][id]

      const measureData = vessel.measureData

      if (!measureData) return
      // 数据格式
      this.currentMeasureEntity = this.cesiumBox().getDrawMeasureData(measureData, vessel.data, TOOL_COLOR_LIST[vessel.data.drawType])
    },

    resetMeasureEntity(data = this.currentMeasureEntity) {
      data = data || {}
      if (Object.keys(data).length > 0) {
        Object.keys(data).map((key) => {
          data[key].remove()
        })
      }
    },

    changeOperateTab(index) {
      this.currentTabIndex = index

      // 力量调派，需要返回外景
      if (index === 1) {
        this.changeSceneStatus(false)
      }
    },

    changeSceneStatus(isInside) {
      this.$parent.changeSceneStatus(isInside)
    }
  }
}
</script>

<style>
.whiteboard-switch {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #061121;

  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 10;
}
</style>
