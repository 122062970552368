<template>
  <div class="whiteboard-container">
    <p class="active">
      <span>白板</span>
    </p>

    <!-- 白板的画笔颜色选择 -->
    <!-- <ul class="whiteboard-colors">
      <li v-for="color in colors" :key="color" :style="{ backgroundColor: color }" @click="selectColor(color)">
        <svg v-if="color === currentColor" t="1629447416355" class="icon" viewBox="0 0 1435 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2089" width="16" height="16"><path d="M1190.080346 41.12191L573.763165 669.509701 244.481006 333.885358a141.573938 141.573938 0 0 0-201.31323 1.738986 148.42759 148.42759 0 0 0-1.738986 205.200376l430.654827 439.247465a141.573938 141.573938 0 0 0 203.052216 0l717.792143-732.010913A148.42759 148.42759 0 0 0 1391.291283 42.963189a141.573938 141.573938 0 0 0-201.210937-1.738986z" fill="#ffffff" p-id="2090"></path></svg>
      </li>
    </ul> -->
  </div>
</template>

<script>
/**
 * 白板侧边菜单
 */

const colors = ['#E0310E', '#11A975', '#3385FF', '#FFD133']

export default {
  data() {
    return {
      colors,
      currentColor: colors[0]
    }
  },

  methods: {
    selectColor(color) {
      this.currentColor = color
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.whiteboard-container {
  @include flex-center();
  position: relative;
  & > p {
    @include size(133px);
    @include flex-center(center);
    box-sizing: border-box;

    color: #fff;
    font-size: var(--zk-min-size);
    background-color: #0a2543;
    cursor: pointer;

    &:first-child {
      margin-right: 18px;
    }
    &.active {
      color: var(--zk-text);
    }
  }

  .whiteboard-colors {
    @include flex-center(center, column);
    position: absolute;
    left: -64px;
    top: 0;
    z-index: 3;
    li {
      @include size(48px);
      cursor: pointer;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
