export default [
  {
    key: 'time',
    label: '时间',
    options: ['上午', '中午', '下午', '晚上', '深夜', '凌晨']
  },
  {
    key: 'area',
    label: '面积',
    options: ['100㎡以下', '100㎡-300㎡', '300㎡-500㎡', '500㎡-1000㎡', '1000㎡以上']
  },
  {
    key: 'humanTraffic',
    label: '人流量',
    options: ['高峰', '闲时', '无人']
  },
  {
    key: 'reason',
    label: '原因',
    options: ['线路老化期货', '漏电起火', '烟头起火', '静电起火', '未知']
  },
  {
    key: 'creep',
    label: '蔓延',
    options: ['迅速', '快', '慢']
  },
  {
    key: 'peopleTrappedIn',
    label: '受困人员',
    options: ['有', '无']
  },
  {
    key: 'risk',
    label: '危险性',
    options: ['浓烟', '易燃液体', '毒气', '未知']
  }
]
