<template>
  <ul class="right-tab menu-box">
    <li v-for="(item, index) in tabs" :key="item" :class="{ active: index === currentTabIndex }" @click="selectTab(index)">
      <span>{{ item }}</span>
    </li>
  </ul>
</template>

<script>
const TABS = ['灾情设定', '力量调派', '作战部署']

export default {
  data() {
    return {
      tabs: TABS,

      currentTabIndex: 0
    }
  },

  created() {
    this.$$event = [['power.for.powerTransfer', this.selectTab.bind(this)]]

    this.$$event.forEach(item => this.$EventBus.$on(...item))
  },

  beforeDestroy() {
    this.$$event.forEach(item => this.$EventBus.$off(...item))
  },

  methods: {
    selectTab(index) {
      if (index === this.currentTabIndex) index = -1

      this.currentTabIndex = index
      this.$emit('tab', index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '../common';

.right-tab {
  li {
    @include size(48px, 87px);
    color: #fff;
    border-bottom: 1px solid #143e6e;
    cursor: pointer;
    @include flex-center(center);

    span {
      width: 14px;
      display: block;
      font-size: var(--zk-size);
      line-height: 1.2;
    }

    &.active {
      color: var(--zk-text);
      background-color: rgba(20, 62, 110, 0.4);
    }
  }
}
</style>
