import AMap from 'AMap'
import Mixin from './Mixin'

import ZK from '@gis/zk-base'

import EventBus from '@/utils/bus'
import { cartesian3ToLonLat } from '@/utils/cesiumUtil'
import { DEPLOY_GRADE_POLYGON_COLOR } from '@/utils/conf'
import coordinateTransform from '@/utils/coordinateTransform'

import { Message, MessageBox } from 'element-ui'

export default class Outside extends Mixin {
  cacheEntity = {}

  cacheLists = []

  constructor(scope) {
    super()
    this._scope = scope

    this.initEvent()
  }

  destroy() {
    this.clearStorage()

    this.removeEvent && this.removeEvent()
    this.offListeners && this.offListeners()
  }

  initEvent() {
    this.$events = [
      ['draw.success.callback.outside', this.successDraw.bind(this)],
      ['draw.delete.callback.outside', this.handlerDeleteDrawData.bind(this)]
    ]

    this.$events.forEach(item => EventBus.$on(...item))

    this.removeEvent = () => {
      this.$events.forEach(item => EventBus.$off(...item))
    }
  }

  cesiumBox() {
    return this._scope.cesiumBox()
  }

  async startDraw(drawData) {
    // 获取对应的配置表
    const data = await this.fetchDrawConfByTaskId(drawData)

    if (data.config && typeof data.config === 'string') {
      data.config = JSON.parse(data.config)
    }

    this.cesiumBox().handlerCommonTrack({
      ...data,
      key: data.key || data.config.drawType
    })
  }

  async successDraw(data) {
    if (data.onlyPoint) return

    if (!(data.positions instanceof Array)) {
      const { lon, lat } = cartesian3ToLonLat(window.viewerData, data.positions)

      data.longitude = lon
      data.latitude = lat
      data.location = await this.getAddress(lon, lat)
    }

    this._scope?.popupConfirm(data)
  }

  //获取地理信息
  getAddress(longitude, latitude) {
    const { lng, lat } = coordinateTransform.wg842Gcj02(longitude, latitude)

    return new Promise((resolve) => {
      AMap.service('AMap.Geocoder', () => {
        const geocoder = new AMap.Geocoder()
        geocoder.getAddress([lng, lat], (status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            resolve(result.regeocode.formattedAddress)
          }
        })
      })
    })
  }

  clearEntity() {
    this.cacheLists.forEach((item) => {
      try {
        item.primitive.remove()
        item.overlay.destroy()
      } catch (error) {
        console.log(error)
      }
    })

    this.cacheLists = []
  }

  handlerDeleteDrawData(data) {
    MessageBox.confirm('确定删除该元素？', '提示').then(() => {
      this._scope.requestRemoveEntity(data.id).then(() => {
        this.removeEntity(data.id)
        Message.success({
          message: '删除成功',
          type: 'success',
          duration: 1500
        })
      })
    })
  }

  // 根据对应id删除
  removeEntity(id) {
    this.cacheLists = this.cacheLists.filter((item) => {
      if (+item.id === +id) {
        try {
          item.primitive.remove()
          item.overlay.destroy()
        } catch (error) {
          console.log(error)
        }
        return false
      }
      return true
    })
  }

  /**
   * 渲染绘图数据组
   * @param {Array} data
   */
  renderDrawData(data) {
    for (const item of data) {
      this.renderDrawDataItem(item)
    }
  }

  /**
   * 渲染绘图数据
   * @param {Object} item
   */
  renderDrawDataItem(item) {
    item = this.formatOutsideDrawData(item)

    try {
      // 获得配置表
      const config = item.config ? JSON.parse(item.config) : {}
      const drawConfig = JSON.parse(item.drawTool.config)
      drawConfig.options = drawConfig.options || {}

      const key = item.id

      // 绘画工具id
      const drawToolId = item.drawTool.id
      // 生成信息弹窗
      const overlay = this.cesiumBox().generateOverlay({ ...item, drawConfig, isViews: item.isView })
      // 获取绘制类
      const entityGenerate = this.cesiumBox().getEntity()

      let positions = config.positions
      if (positions instanceof Array) {
        if (positions[0] instanceof Object) {
          positions = positions.map(p => new Cesium.Cartesian3(p.x, p.y, p.z))
        }
      } else {
        positions = new Cesium.Cartesian3(positions.x, positions.y, positions.z)
      }

      const icon = drawConfig?.marker?.url || item?.drawTool?.icon
      let type = drawConfig.child || drawConfig.drawType

      // @TODO: 后续要将消防设施等点类型改成textLabel
      if (type === 'point') {
        type = 'textLabel'

        drawConfig.options = {
          ...drawConfig.options,
          color: '#fff',
          backgroundColor: '#f39c12'
        }
      }

      const entityId = `${drawToolId}-${key}`

      if (!config.label) {
        config.label = {
          text: item.drawName
        }
      }

      const primitive = entityGenerate[type](entityId, positions, {
        iconWidth: 30,
        iconHeight: 40,
        text: item.drawName,
        modelUrl: item.modelUrl,
        label: config.label || '',
        minimumHeights: config.minimumHeights || [],
        waterPositions: drawConfig.waterPositions || [],
        waterPipePositions: drawConfig.waterPipePositions || [],
        centerPosition: { lon: item.longitude, lat: item.latitude },
        icon,
        itemInfo: item,
        pointIcon: icon,
        pointPixelOffset: [0, -20],
        ...drawConfig.options,
        backgroundColor: item.color || drawConfig.options.backgroundColor,
        distanceDisplayCondition: [0, Number.POSITIVE_INFINITY]
      })

      if (!this.cacheEntity[drawToolId]) {
        this.cacheEntity[drawToolId] = {}
      }

      // 添加实体
      // 这个对象用来查询
      this.cacheEntity[drawToolId][key] = {
        primitive,
        overlay,
        data: item
      }
      // 这个用来方便做清除操作或循环查询
      if (!this.cacheLists) this.cacheLists = []

      this.cacheLists.push({
        id: key,
        overlay,
        primitive,
        data: item,
        drawToolId
      })
    } catch (error) {
      console.log(error)
    }
  }

  // 格式化数据
  formatOutsideDrawData(item) {
    let config = {}
    try {
      config = JSON.parse(item.config)
    } catch (error) {
      /* error */
    }

    // 添加描述
    if (!config.options) config.options = {}

    config.options.description = item.description
    config.options.backgroundColor = DEPLOY_GRADE_POLYGON_COLOR[item.grade - 1]

    let positions = item.position
    let { jd, wd } = item

    if (positions && typeof positions === 'string') {
      positions = JSON.parse(positions)
    } else if (jd && wd) {
      positions = Cesium.Cartesian3.fromDegrees(jd, wd, 2)
    }

    if (+item.deployTypeId === 55) {
      config.drawType = 'wallGeometry'
    }

    return {
      id: item.id,
      drawName: item.deployType.name,

      config: JSON.stringify({ positions }),

      drawTool: {
        id: item.deployType.taskId,
        config: JSON.stringify(config)
      },
      // 保留一份原始数据
      origin: item
    }
  }

  listenMouseEvent() {
    const viewer = window.viewerData
    const listeners = [
      [ZK.MouseEventType.CLICK, this.handlerLeftClick.bind(this)],
      [ZK.MouseEventType.WHEEL, this.handlerHideLastOverlay.bind(this)],
      [ZK.MouseEventType.PINCH_MOVE, this.handlerHideLastOverlay.bind(this)]
    ]

    listeners.forEach(item => viewer.on(...item))

    this.offListeners = () => {
      console.log('销毁订阅事件')
      listeners.forEach(item => viewer.off(...item))
    }
  }

  handlerLeftClick({ target }) {
    this.handlerHideLastOverlay()

    // 绘画时禁止
    if (this.cesiumBox().isDrawing) return

    if (Cesium.defined(target)) {
      // 一般情况下，点击Entity
      if (Cesium.defined(target.id)) {
        // 这是建筑体的弹窗
        const objId = target.id._objId
        this.changeOverlay(objId, true)
        return
      } else if (Cesium.defined(target.collection)) {
        // 处理点击粒子聚合（eg：火）

        const particleId = target.collection?._textureAtlasGUID
        if (particleId) {
          // 循环查询对应的
          const result = this.cacheLists.find((item) => {
            return item?.primitive?.particle?._billboardCollection?._textureAtlasGUID === particleId
          })

          if (result) {
            this.changeOverlay(`${result.drawToolId}-${result.id}`, true)
            return
          }
        }
      }
    }
  }

  handlerHideLastOverlay() {
    // 建筑体弹窗
    if (this.lastOverlayId) {
      this.changeOverlay(this.lastOverlayId)
      this.lastOverlayId = null
    }
  }

  changeOverlay(objId, isShown = false) {
    if (!objId) return

    const [root, id] = objId.split('-')

    let vessel = {}

    if (this.cacheEntity[root] && this.cacheEntity[root][id]) {
      vessel = this.cacheEntity[root][id]
    }

    const overlay = vessel.overlay

    if (!overlay) return

    overlay[isShown ? 'show' : 'hide']()

    if (isShown) {
      this.lastOverlayId = objId
    }
  }

  removeDataByGrade(grade) {
    this.cacheLists = this.cacheLists.filter((item) => {
      if (item.data.origin.grade > grade) {
        item.primitive.remove()
        item.overlay && item.overlay.destroy()
        return false
      }
      return true
    })
  }
}

export const $$outside = new Outside()
