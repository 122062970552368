<template>
  <div class="deploy-whiteboard">
    <!-- 白板 -->
    <white-board ref="whiteboardCom" :offsettop="48" class="whiteboard-main" @load="loadComplete" />

    <!-- 白板菜单栏 -->
    <ul class="whiteboard-menu">
      <!-- 笔 -->
      <li class="whiteboard-menu_item_icon" @click="setWhiteboardTool('pen')">
        <svg t="1629707712556" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4924" width="32" height="32">
          <path
            d="M624.725333 754.901333l-369.493333-213.333333 234.752-406.4a128 128 0 0 1 174.848-46.848l147.712 85.290667a128 128 0 0 1 46.848 174.848l-234.666667 406.442666z m49.92-513.109333a42.666667 42.666667 0 0 0-58.282666 15.616l-128 221.696a42.666667 42.666667 0 1 0 73.898666 42.666667l128-221.696a42.666667 42.666667 0 0 0-15.616-58.282667zM238.08 827.264l147.797333 85.333333-22.186666 38.485334-190.165334-11.989334 64.554667-111.786666z m-4.181333-248.746667l369.493333 213.333334c-17.066667 29.568-41.557333 48.256-73.472 56.106666-31.914667 7.850667-66.645333 20.650667-104.234667 38.4l-184.746666-106.666666c-3.456-41.429333-9.813333-77.952-18.944-109.482667-9.130667-31.573333-5.162667-62.122667 11.904-91.690667z"
            :fill="toolType === 'pen' ? '#18cffd' : '#ffffff'"
            p-id="4925"
          />
        </svg>
      </li>

      <!-- 橡皮擦 -->
      <li class="whiteboard-menu_item_icon" @click="setWhiteboardTool('eraser')">
        <svg t="1629708232106" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7735" width="32" height="32">
          <path
            d="M889.6 403.2 620.8 134.4 563.2 192 537.6 166.4 179.2 531.2l25.6 25.6L166.4 582.4c-51.2 51.2-51.2 128 0 179.2l89.6 89.6c51.2 51.2 128 51.2 179.2 0l32-32 25.6 25.6 364.8-364.8L832 460.8 889.6 403.2zM620.8 179.2l224 224-38.4 38.4L582.4 211.2 620.8 179.2zM428.8 819.2c-44.8 44.8-115.2 44.8-160 0l-70.4-70.4c-44.8-44.8-44.8-115.2 0-160L224 576 448 800 428.8 819.2z"
            :fill="toolType === 'eraser' ? '#18cffd' : '#ffffff'"
            p-id="7736"
          />
        </svg>
      </li>

      <!-- 颜色选择 -->
      <li class="whiteboard-menu_item_icon color" @click="showColorPicker = !showColorPicker">
        <!-- 颜色选择卡 -->
        <div v-if="showColorPicker" class="settings-colorpicker">
          <ul class="brush-size">
            <li v-for="size in BRUSH_SIZE" :key="size" :class="{ active: currentBrushSize === size }" @click="sizeBrushSize(size)"></li>
          </ul>
          <color-picker :onSelectColor="setShapeColor" :colors="colors" />
        </div>
        <span :style="{ backgroundColor: currentColor }"></span>
      </li>

      <!-- 撤销 -->
      <li class="whiteboard-menu_item_icon" @click="whiteboardUndo">
        <svg t="1629707796981" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6089" width="32" height="32">
          <path
            d="M502.897778 368.071111h-6.371556V228.295111c0-52.224-30.037333-73.955556-67.128889-44.259555l-344.746666 276.195555c-37.034667 29.582222-37.034667 78.165333 0.113777 107.747556l342.129778 273.464889c37.091556 29.582222 69.745778-0.455111 69.745778-44.544v-147.342223h51.768889c149.617778 0 263.793778 64.284444 333.653333 188.529778 13.710222 22.584889 28.16 17.635556 28.16 0-3.015111-221.297778-191.146667-470.129778-407.324444-470.129778z"
            fill="#ffffff"
            p-id="6090"
          />
        </svg>
      </li>

      <!-- 取消 -->
      <li class="whiteboard-menu_item_icon" @click="cancelDraw">
        <svg t="1630388834757" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5888" width="32" height="32">
          <path
            d="M613.316 509.58l224.879-224.871c28.213-28.221 28.213-73.963 0-102.176-28.221-28.214-73.956-28.214-102.184 0l-224.87 224.863-224.872-224.863c-28.22-28.214-73.955-28.214-102.183 0-28.214 28.213-28.214 73.962 0 102.176l224.871 224.87-224.871 224.879c-28.214 28.22-28.214 73.962 0 102.183a72.048 72.048 0 0 0 51.088 21.162 72.048 72.048 0 0 0 51.088-21.162l224.871-224.878L736.011 836.64a72.048 72.048 0 0 0 51.088 21.162 72.033 72.033 0 0 0 51.088-21.162c28.214-28.22 28.214-73.962 0-102.183L613.317 509.58z"
            fill="#d81e06"
            p-id="5889"
          ></path>
        </svg>
      </li>

      <!-- 重画 -->
      <li class="whiteboard-menu_item_icon" @click="clearWhiteBoard">
        <svg t="1630388762712" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5512" width="25" height="25">
          <path
            d="M35.84 40.96v393.216h393.216l-143.36-143.36C343.04 233.472 420.864 196.608 506.88 196.608c176.128 0 315.392 143.36 315.392 315.392 0 176.128-143.36 315.392-315.392 315.392-143.36 0-266.24-102.4-303.104-237.56288H44.032c40.96 221.184 233.47712 393.216 466.944 393.216 258.048 0 471.04-208.896 471.04-471.04s-212.992-471.04-471.04-471.04C379.904 40.96 261.12 94.208 175.104 180.224L35.84 40.96z"
            fill="#ffffff"
            p-id="5513"
          ></path>
        </svg>
      </li>

      <!-- 完成 -->
      <li class="whiteboard-menu_item_icon" @click="completeDraw">
        <svg t="1630388811515" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5700" width="32" height="32">
          <path
            d="M935.03 212.628c-28.659-28.662-75.123-28.662-103.78 0l-449.723 449.72L191.26 472.08c-28.66-28.655-75.124-28.655-103.784 0-28.656 28.662-28.656 75.124 0 103.786l242.16 242.156c28.657 28.654 75.123 28.654 103.781 0L935.03 316.404c28.66-28.66 28.66-75.122 0-103.776z"
            fill="#18CFFD"
            p-id="5701"
          ></path>
        </svg>
      </li>
    </ul>

    <!-- 优化项，画板加载图片存在一定延迟，用一个蒙层挡住。避免误触 -->
    <div v-if="loading" class="whiteboard-mask"></div>
  </div>
</template>

<script>
/**
 * 白板实际绘制模块
 * 截图模块(目前是整张图保存下来)
 */
import api from '@/api/sandTable'
import HtmlHostMsg from 'HtmlHostMsg'
import WhiteBoard from '@/components/WhiteBoard'
import ColorPicker from '@/components/ColorPicker'

import { uploadFile } from '@/api/upload'
import { dataURLtoFile, uploadPreprocess } from '@/utils/util'

import colorPalette from '@/config/colorPalette'

const defaultType = 'pen'

const BRUSH_SIZE = [2, 5, 10]

export default {
  components: {
    WhiteBoard,
    ColorPicker
  },

  inject: ['cesiumBox', 'isInside'],

  data() {
    return {
      toolType: defaultType,
      BRUSH_SIZE,
      colors: colorPalette,
      showColorPicker: false,

      currentColor: '',
      currentBrushSize: BRUSH_SIZE[1],

      loading: false
    }
  },

  mounted() {
    this.loading = true
    this.$nextTick(() => {
      this.initWhiteBoard()
    })
  },

  beforeDestroy() {
    this.handlerHierarchy(false)
  },

  watch: {
    // 内景截图成功回调
    '$store.state.planDrill.screenUnitDraw': {
      handler(val) {
        this.$nextTick(() => {
          this.setWhiteBoardBackgroundImage(val.dataUrl)
          this.setWhiteboardTool(defaultType)
        })
      }
    }
  },

  methods: {
    initWhiteBoard() {
      if (this.$store.state.planDrill.enterInside) {
        HtmlHostMsg.event('screen', {})
      } else {
        this.screenshotsOutside()
      }

      this.currentColor = this.$refs.whiteboardCom.brushColor
    },

    loadComplete() {
      this.loading = false
      this.handlerHierarchy(true)
    },

    // 处理三维图层
    handlerHierarchy(status) {
      this.$EventBus.$emit('hide.operate.inside.status', status)
      this.$EventBus.$emit('hide.operate.cesiumbox.status', status)
    },

    // 处理外景白板
    screenshotsOutside() {
      this.cesiumBox()
        .captureScreenshot()
        .then((url) => {
          this.$nextTick(() => {
            this.setWhiteBoardBackgroundImage(url)
            this.setWhiteboardTool(defaultType)
          })
        })
    },

    // 设置当前白板工具
    setWhiteboardTool(type) {
      this.toolType = type
      this.$refs.whiteboardCom.setPenSlide(type === 'eraser' ? 10 : 5)
      this.$refs.whiteboardCom.setCanvasTool(type)
    },

    // 设置画笔颜色
    setShapeColor(color) {
      this.currentColor = color
      this.$refs.whiteboardCom.setBrushColor(color)
    },

    sizeBrushSize(size) {
      this.currentBrushSize = +size
      this.$refs.whiteboardCom.setPenSlide(size)
    },

    // 白板撤回
    whiteboardUndo() {
      this.$refs.whiteboardCom.undo()
    },

    // 修改白板底图
    setWhiteBoardBackgroundImage(url) {
      this.$refs.whiteboardCom.setBackgroundImage(url)
    },

    // 取消绘制
    cancelDraw() {
      this.$emit('cancel')
    },

    clearWhiteBoard() {
      this.$refs.whiteboardCom.clearCanvas()
    },

    // 完成绘制
    completeDraw() {
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$refs.whiteboardCom
        .exportImage()
        // 生成md5，base64转file
        .then(base64 => uploadPreprocess(dataURLtoFile(base64)))
        // 上传
        .then(params => uploadFile(params))
        .then((url) => {
          return api.whiteBoard.add({
            imageUrl: url,
            sandTableId: this.$route.query.sandTableId
          })
        })
        .then(() => {
          setTimeout(() => {
            loading.close()
            this.$message.success('保存成功')

            this.cancelDraw()
            // 刷新白板记录列表
            this.$EventBus.$emit('refresh.whiteboard.record')
          }, 1200)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.deploy-whiteboard {
  .whiteboard-main {
    @include size(100vw, calc(100vh - 48px));
  }
}

.whiteboard-mask {
  @include size(100vw, 100vh);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.whiteboard-menu {
  height: 48px;
  line-height: 48px;
  @include flex-center();
  user-select: none;
  background-color: rgba(1, 18, 38, 0.9);
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  &_item {
    @include size(96px, 100%);
    color: #c1cee1;
    text-align: center;
    font-size: var(--zk-size);
    cursor: pointer;
    position: relative;
    color: var(--zk-text);
    transition: opacity 0.2s;

    &::before {
      content: '';
      @include size(1px, 16px);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #4d5868;
    }

    &:hover {
      opacity: 0.8;
    }

    &_icon {
      width: 80px;
      @include flex-center(center);
      cursor: pointer;

      &.color {
        @include flex-center();
        span {
          @include size(24px);
          border-radius: 50%;
          border: 2px solid #fff;
        }
      }

      .settings-colorpicker {
        width: 176px;
        padding: 20px;
        box-sizing: border-box;
        position: absolute;
        top: -206px;
        background-color: rgba(1, 18, 38, 0.9);
        ul {
          width: 100%;
          @include flex-center(center);
          margin-bottom: 20px;
          li {
            border-radius: 50%;
            background-color: #fff;
            &.active {
              border: 2px solid var(--zk-color);
            }
            &:nth-child(1) {
              @include size(5px);
              margin-right: 20px;
            }
            &:nth-child(2) {
              @include size(10px);
              margin-right: 20px;
            }
            &:nth-child(3) {
              @include size(15px);
            }
          }
        }
      }
    }
  }
}
</style>
