import EventBus from '@/utils/bus'
import { cartesian3ToLonLat, getHeading } from '@/utils/cesiumUtil'
import { getUnitDrawListOutside } from '@/api/file-info'
import Store from '@/store'
import ZK from '@gis/zk-base'
import { Message } from 'element-ui'

import carIcon from '@/assets/images/model/car.png'

function getCarScale(modelUrl) {
  // 枚举车模型,根据不同车型设置相应的显示倍数
  const modeList = [
    { modelUrl: 'climb', scale: 0.05 }, //登高车
    { modelUrl: 'foam', scale: 0.012 }, //泡沫车
    { modelUrl: 'high_spray', scale: 0.01 }, //高喷车
    { modelUrl: 'water', scale: 0.07 }, //水罐车
    { modelUrl: 'main_station', scale: 0.035 }, //主战车
    { modelUrl: 'jug', scale: 0.03 }, //水罐车1
    { modelUrl: 'jh', scale: 0.01 } //救护车
  ]

  const result = modeList.find(item => new RegExp(`${item.modelUrl}_car.gltf$`).test(modelUrl))

  return result ? result.scale : 0.015
}

export default class Car {
  cacheEntity = {}

  cacheLists = []

  lastCarPosition = ''

  constructor(scope) {
    this._scope = scope
    this.lastPicker = null
    this.initEvent()
  }

  ready() {
    this.listenMouseEvent()
  }

  destroy() {
    this.removeEvent && this.removeEvent()
    this.offListeners && this.offListeners()
  }

  initEvent() {
    this.$events = [['draw.success.callback.outside', this.successDraw.bind(this)]]

    this.$events.forEach(item => EventBus.$on(...item))

    this.removeEvent = () => {
      this.$events.forEach(item => EventBus.$off(...item))
    }
  }

  cesiumBox() {
    return this._scope.cesiumBox()
  }

  startDraw(drawData) {
    this.cesiumBox().handlerCommonTrack({
      key: 'pointModel',
      ...drawData
    })
  }

  successDraw(data) {
    const { lon, lat } = cartesian3ToLonLat(window.viewerData, data.positions)

    this._scope?.popupConfirm({
      ...data,
      latitude: lat,
      longitude: lon
    })
  }

  /**
   * 渲染绘图数据组
   * @param {Array} data
   */
  renderDrawData(data) {
    for (const item of data) {
      this.renderDrawDataItem(item)
    }
  }

  /**
   * 渲染绘图数据
   * @param {Object} item
   */
  renderDrawDataItem(item) {
    const { carId, organId, modelUrl, carName, operationJson } = item
    try {
      // 获得配置表
      // const drawConfig = JSON.parse(item.drawTool.config)
      // drawConfig.options = drawConfig.options || {}

      // 获取绘制类
      const entityGenerate = this.cesiumBox().getEntity()

      let positions = JSON.parse(item.position)
      if (positions instanceof Array) {
        if (positions[0] instanceof Object) {
          positions = positions.map(p => new Cesium.Cartesian3(p.x, p.y, p.z))
        }
      } else {
        positions = new Cesium.Cartesian3(positions.x, positions.y, positions.z)
      }

      const entityId = `${organId}-${carId}`

      const primitive = entityGenerate.pointModel(entityId, positions, {
        modelUrl,
        text: carName,
        color: '#fff',
        pointIcon: carIcon,
        pointIconWidth: 32,
        pointIconHeight: 32,
        backgroundColor: '#3a93da',
        scale: getCarScale(modelUrl),
        // waterPositions: drawConfig.waterPositions || [],
        // waterPipePositions: drawConfig.waterPipePositions || [],
        centerPosition: { lon: item.longitude, lat: item.latitude },
        distanceDisplayCondition: [0, Number.POSITIVE_INFINITY],
        ...JSON.parse(operationJson).options,
        ...JSON.parse(operationJson).waterGunData
      })

      if (!this.cacheEntity[organId]) {
        this.cacheEntity[organId] = {}
      }

      // 添加实体
      // 这个对象用来查询
      this.cacheEntity[organId][carId] = {
        primitive,
        data: item
      }
      // 这个用来方便做清除操作或循环查询
      if (!this.cacheLists) this.cacheLists = []

      this.cacheLists.push({
        id: carId,
        organId,
        primitive,
        data: item
      })
    } catch (error) {
      console.log(error)
    }
  }

  // 根据对应id删除
  removeEntity(id) {
    this.cacheLists = this.cacheLists.filter((item) => {
      if (+item.id === +id) {
        try {
          item.primitive.remove()
        } catch (error) {
          console.log(error)
        }
        return false
      }
      return true
    })
    this.removeOverlay()
  }

  clearEntity() {
    this.cacheLists.forEach((item) => {
      try {
        item.primitive.remove()
      } catch (error) {
        console.log(error)
      }
    })

    this.cacheLists = []
  }

  listenMouseEvent() {
    const viewer = window.viewerData

    const handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)

    const listeners = [
      [this.handlerLeftDown.bind(this), Cesium.ScreenSpaceEventType.LEFT_DOWN],
      [this.handlerLeftUp.bind(this), Cesium.ScreenSpaceEventType.LEFT_UP]
    ]

    listeners.forEach(item => handler.setInputAction(...item))

    this.offListeners = () => {
      console.log('销毁订阅事件')
      handler.destroy()
    }
  }

  // 监听鼠标移动事件，针对移动车辆
  handlerLongPress(data) {
    const viewer = window.viewerData
    console.log('长按事件触发')

    Message.success({ message: '移动车辆', type: 'success', duration: 1500 })

    this.longpress = true
    const handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas)

    // 锁定视角
    viewer.scene.screenSpaceCameraController.enableRotate = false

    handler.setInputAction((event) => {
      // 获取移动位置
      let cartesian = viewer.scene.camera.pickEllipsoid(event.endPosition, viewer.scene.globe.ellipsoid)

      // 修改实体位置
      data.primitive.entity.forEach((item) => {
        item.position = cartesian
      })

      this.lastCarPosition = cartesian
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    this.offLoopPressEvent = () => {
      console.log('销毁长按移动事件')
      handler.destroy()
    }
  }

  handlerLeftDown(event) {
    const target = window.viewerData.scene.pick(event.position)

    const resetTimer = () => {
      clearTimeout(this.longPressTimer)
      this.longPressTimer = null
    }

    if (this.longPressTimer) resetTimer()

    // 绘制的时候return
    if (this.cesiumBox().isDrawing) return

    // 判断点击的是否是车辆，是进行长按判断
    this.checkIsCar(target).then((data) => {
      this.isLeftDown = true

      this.longPressTimer = setTimeout(() => {
        if (this.isLeftDown) {
          this.handlerLongPress(data)
        }
        resetTimer()
      }, 1500)
    })
  }

  async handlerLeftUp(event) {
    const viewer = window.viewerData
    const target = viewer.scene.pick(event.position)
    console.log('松开')

    const data = await this.checkIsCar(target)

    // 松开清除长按事件，取消监听移动事件
    this.isLeftDown = false
    if (this.longpress) {
      this.longpress = false
      this.offLoopPressEvent && this.offLoopPressEvent()
      // 松开鼠标，视角开锁
      viewer.scene.screenSpaceCameraController.enableRotate = true

      this._scope?.changeCarPostion({
        data,
        position: this.lastCarPosition
      })
    } else {
      // 点击
      if (this.cesiumBox().isDrawing) return
      //记录最后一次点击的车实体数据
      this.lastPicker = data
      Store.commit('exterior/SET_ENTITY_DATA', data.data)
      this._scope?.popupCarModel(data.data)
    }
  }

  // 检查对象是否是车辆
  checkIsCar(target) {
    return new Promise((resolve) => {
      if (Cesium.defined(target) && Cesium.defined(target.id)) {
        const objId = target.id._objId

        if (!objId) return

        const [root, id] = objId.split('-')

        if (this.cacheEntity[root] && this.cacheEntity[root][id]) {
          resolve(this.cacheEntity[root][id])
        }
      }
    })
  }

  flyToCar(organId, id) {
    const data = this.cacheEntity[organId][id]

    if (!data) return
    let offset = new Cesium.HeadingPitchRange(Cesium.Math.toRadians(0), -Cesium.Math.toRadians(45), 100)

    window.viewerData.flyTo(data.primitive.entity, {
      offset,
      duration: 1.2
    })
  }

  //清除消防车点击显示附近消火栓
  clearHydrant() {
    if (this.hydrantList) {
      this.hydrantList.forEach(item => item.remove())
      this.hydrantList = []
    }
  }

  // 消防车点击显示附近消火栓
  setHydrant(data) {
    this.hydrantList = []
    const viewer = window.viewerData
    const { lon, lat } = cartesian3ToLonLat(viewer, JSON.parse(data.data.config).positions)
    getUnitDrawListOutside({ radius: 500, lon, lat }).then((res) => {
      const entityGenerate = this.cesiumBox().getEntity()

      this.hydrantList = res.map((item) => {
        const { marker } = JSON.parse(item.drawTool.config)
        return entityGenerate.textLabel(`${item.drawToolId}-${item.id}`, JSON.parse(item.config).positions, {
          text: item.drawName,
          pointIcon: marker.url,
          labelPixeOffset: [0, -60],
          pointPixelOffset: [0, -20],
          backgroundColor: '#1224ff',
          color: '#fff'
        })
      })
    })
  }

  // 根据grade 删除比传入grade要小的值
  removeDataByGrade(grade) {
    this.cacheLists = this.cacheLists.filter((item) => {
      if (item.data.grade > grade) {
        item.primitive.remove()
        return false
      }
      return true
    })
  }

  //旋转
  entityRotate(angle) {
    const { operationJson, position } = this.lastPicker.data
    if (angle === undefined) {
      angle = JSON.parse(operationJson)?.options?.rotate
    }
    this.lastPicker.primitive.entity.forEach((item) => {
      if (item.orientation) {
        item.orientation = Cesium.Transforms.headingPitchRollQuaternion(JSON.parse(position), new Cesium.HeadingPitchRoll(Cesium.Math.toRadians(angle), 0.0, 0.0))
      }
    })
  }

  //喷水枪
  openWaterGun(pointB) {
    const viewer = window.viewerData
    if (!this.layer) {
      this.layer = new ZK.PrimitiveLayer('layer')
      viewer.addLayer(this.layer)
    }
    this.removeOverlay()

    const { longitude, latitude } = this.lastPicker.data
    const head = getHeading([longitude, latitude], [pointB.longitude, pointB.latitude])

    const waterGunData = {
      radius: 2, //粒子特效范围半径
      imageSize: [20, 20, 20, 20], //单个粒子的图片大小，像素单位，可设置为Number或[imageWidth, imageHeight]或[minimumImageWidth, minimumImageHeight, maximumImageWidth, maximumImageHeight]，数组内均为Number
      scale: [1, 5], //单个粒子缩放范围，可设置Number或[startScale, endScale]，数组内均为Number
      particleLife: [1, 4], //单个粒子存活时间范围，可设置Number或[minimumParticleLife, maximumParticleLife]，数组内均为Number
      speed: [25, 25], //单位粒子运动速度范围，可设置Number或[minimumSpeed, maximumSpeed]，数组内均为Number
      emissionRate: [120], //每秒产生的粒子数
      lifetime: 16, //粒子特效持续时间，loop为false时生效
      loop: true, //为true表示粒子特效一直存在，默认true
      headpitchroll: [head, 45, 0], //角度
      gravity: -8, //重力
      opacity: [1.0, 0.5] //单个粒子透明度变化范围，可设置Number或[startOpacity, endOpacity]，数组内均为Number
    }
    this.water = new ZK.FountainParticle([longitude, latitude])
    this.water.setStyle(waterGunData)

    this.layer.addOverlay(this.water, {
      speed: 100
    })

    return waterGunData
  }

  removeOverlay() {
    if (this.water) {
      this.layer.removeOverlay(this.water)
      this.water = null
    }
  }
}
