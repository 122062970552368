import api from '@/api/sandTable'

// 储存已经请求过的部署配置表数据
let storageInsideDrawConfByTaskId = []

export default class Mixin {
  constructor() {}

  clearStorage() {
    storageInsideDrawConfByTaskId = []
  }

  fetchDrawConfByTaskId({ taskId, id }) {
    const storage = storageInsideDrawConfByTaskId

    const findConf = lists => lists.find(item => +item.id === +id)

    if (storage[taskId]) return Promise.resolve(findConf(storage[taskId]))

    return api.deploy
      .type({
        taskId
      })
      .then((data) => {
        storageInsideDrawConfByTaskId[taskId] = data

        return findConf(data)
      })
  }
}
