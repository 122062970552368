<template>
  <div class="operation-box">
    <select-angle style="margin: 8px 0" ref="selectAngle" @change="changeRotate" />
    <div class="btn-list">
      <div class="btn" @click="openWaterGun">喷水</div>
    </div>
  </div>
</template>

<script>
import SelectAngle from './SelectAngle'
export default {
  components: {
    SelectAngle
  },

  inject: ['cesiumBox'],

  created() {
    this.rotate = undefined
  },
  methods: {
    changeRotate(rota) {
      this.rotate = rota
      this.$EventBus.$emit('rotate.car.change', rota)
    },

    getRotate() {
      return this.rotate
    },

    setAngle(angle) {
      this.$refs.selectAngle.setAngle(angle)
    },

    //喷水-点击事件
    openWaterGun() {
      this.cesiumBox().handlerCommonTrack({ key: 'point', onlyPoint: true })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/mixins';

.operation-box {
  margin-top: 10px;
  font-size: 14px;
  color: #fff;
  .btn-list {
    .btn {
      cursor: pointer;
    }
  }
}
</style>
