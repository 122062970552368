<template>
  <ul class="top-stage">
    <li
      v-for="(item, index) in grade"
      :key="index"
      :class="{
        'top-stage_item': true,
        'top-stage_item--active': index + 1 === currentGrade
      }"
      @click="selectGrade(index)"
    >
      第{{ index | formatGrade }}阶段
    </li>

    <li class="top-stage_plus" v-if="grade.length < MAX_GRADE" @click="addGrage">
      <i class="el-icon-plus"></i>
    </li>
  </ul>
</template>

<script>
import api from '@/api/sandTable'

const MAX_GRADE = 5
const grades = ['一', '二', '三', '四', '五']

export default {
  data() {
    return {
      grade: [1],
      MAX_GRADE,

      currentGrade: 1
    }
  },

  created() {
    this.initGrade()
  },

  methods: {
    initGrade() {
      this.fetchGrade().then((data) => {
        if (!data.length) data = [1]
        this.grade = data
      })
    },

    fetchGrade() {
      return api.deploy.getGrade({
        sandTableId: this.$route.query.sandTableId
      })
    },

    selectGrade(index) {
      const lastCurrentGrade = this.currentGrade
      const grade = index + 1

      if (+grade === +lastCurrentGrade) return

      this.currentGrade = grade

      this.$emit('change', {
        grade,
        // 上一阶段
        lastCurrentGrade
      })
    },

    async addGrage() {
      const data = await this.fetchGrade()
      const grade = this.grade

      if (data.length < grade.length) {
        this.$message.error(`请先对第${grades[grade.length - 1]}阶段进行部署`)
      } else {
        const newGrade = grade[grade.length - 1] + 1

        this.grade.push(newGrade)
        this.selectGrade(newGrade - 1)
      }
    }
  },

  filters: {
    formatGrade(grade) {
      return grades[grade]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.top-stage {
  height: 48px;
  line-height: 48px;
  @include flex-center();
  user-select: none;
  background-color: rgba(1, 18, 38, 0.9);
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  &_item {
    @include size(96px, 100%);
    color: #c1cee1;
    text-align: center;
    font-size: var(--zk-size);
    cursor: pointer;
    position: relative;

    &--active {
      color: var(--zk-text);
      cursor: default;
    }

    &::after {
      content: '';
      @include size(1px, 16px);
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #4d5868;
    }
  }

  &_plus {
    text-align: center;
    color: var(--zk-text);
    font-size: var(--zk-l-size);
    @include size(63px, 100%);
    cursor: pointer;
  }
}
</style>
