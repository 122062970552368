import AMap from 'AMap'
import ZK from '@gis/zk-base'

import api from '@/api/model'
import sandTableApi from '@/api/sandTable'
import { getCurrentFireCenterMap } from '@/api/PlanDrill'

import { BUILD_TYPE_BY_NAME } from '@/utils/conf'
import coordinateTransform from '@/utils/coordinateTransform'

const MAX_HEIGHT = 1500
const HEIGHT_GRADE = [30000, 20000, 18000, 15000]

let tilesets = []

export default {
  inject: ['cesiumBox'],

  mounted() {
    this.$nextTick(() => {
      this.getCurrentModelUrl()
    })
  },

  beforeDestroy() {
    // 清除tile
    if (this.removeTilesetLayerAndOffLister) {
      this.removeTilesetLayerAndOffLister()
    }

    this.resetDrawData()
  },

  methods: {
    getCurrentModelUrl() {
      getCurrentFireCenterMap().then(({ modelUrl, latitude, longitude, grade }) => {
        this.modelUrls = modelUrl.split(',')
        this.init3dTilesetLayer()

        this.$nextTick(() => {
          const viewer = this.cesiumBox().getViewer()
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, HEIGHT_GRADE[grade])
          })
        })
      })
    },

    init3dTilesetLayer() {
      const viewer = this.cesiumBox().getViewer()

      let layer = new ZK.TilesetLayer('layer')
      // 场景添加图层
      viewer.addLayer(layer)

      if (!this.modelUrls) return console.error('没有实景三维数据')
      const tiles = this.modelUrls.map(url => new ZK.Tileset(url))

      // 图层添加tileset组
      layer.addOverlays(tiles)

      tiles.forEach((tile) => {
        // tile加载成功
        tile.readyPromise.then((tileset) => {
          this.cesiumReadyLoad(tileset)
          tileset.dynamicScreenSpaceErrorHeightFalloff = -30
          // tileset.classificationType = Cesium.ClassificationType.CESIUM_3D_TILE
        })
      })

      let listeners = [
        [ZK.MouseEventType.WHEEL, this.handlerWheelEvent.bind(this)],
        [ZK.MouseEventType.PINCH_MOVE, this.handlerWheelEvent.bind(this)],
        [ZK.MouseEventType.CLICK, this.handlerLeftClick.bind(this)]
      ]

      listeners.forEach(item => viewer.on(...item))

      // 这里定义一个可执行的清除tiles事件
      if (this.removeTilesetLayerAndOffLister) {
        this.removeTilesetLayerAndOffLister()
      }

      this.removeTilesetLayerAndOffLister = () => {
        if (layer) {
          viewer.removeLayer(layer)
          layer = null
        }

        listeners.forEach(item => viewer.off(...item))
      }
    },

    cesiumReadyLoad(tilesetData) {
      tilesets.push(tilesetData)
      tilesetData.show = false
      this.tiletVisible = false

      if (this.lock) return

      this.lock = true

      this.fetchCompanyLists()
    },

    handlerLeftClick({ target, surfacePosition }) {
      if (Cesium.defined(target) && Cesium.defined(target.id)) {
        if (target.id instanceof Array) {
          this.cesiumBox().handlerFlyTo(this.cesiumBox().formatPostion([surfacePosition], 2500)[0])
          return
        }

        const objId = target.id._objId

        if (objId) {
          const [, index] = target.id._objId.split('-')

          this.jumpToUnitDetail(this.companyList[index])
        }
      }
    },

    handlerWheelEvent() {
      const height = this.cesiumBox().getCameraHeight()

      const isShown = height < MAX_HEIGHT
      this.tiletVisible = isShown
      tilesets.forEach(item => item.show = isShown)
    },

    /**
     * 飞到指定单位的视角, 如果没有就默认视角
     * 清空视图上的元素(单位点, 全图的tile)
     * 跳转到Operate推演页面, 进行推演
     */
    jumpToUnitDetail(data) {
      this.fetchRangeGetPerspective(data)
        .then(() => {
          // 清除tile
          if (this.removeTilesetLayerAndOffLister) {
            this.removeTilesetLayerAndOffLister()
          }
          // 清除entity
          this.resetDrawData()

          return Promise.resolve()
        })
        .then(() => {
          this.startSandTable(data)
        })
    },

    /**
     * 开始推演
     * 创建新的推演记录，获取推演id并进入推演
     * 后续进入推演后，自动截取外景图片作为封面图
     */
    async startSandTable(data) {
      const loading = this.$loading({
        lock: true,
        text: '获取单位数据...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      sandTableApi.record
        .add({
          unitId: data.id,
          name: `${data.name}推演`
        })
        .then((response) => {
          this.$router.push({
            name: 'Operate',
            query: { id: data.id, sandTableId: response.id, add: 1 }
          })

          this.$EventBus.$emit('refresh.sandTable.history')
        })
        .finally(() => {
          setTimeout(() => {
            loading.close()
          }, 1200)
        })
    },

    resetCamera() {
      this.tiletVisible = false
      tilesets.forEach(item => item.show = false)
    },

    flyToInitCamera() {
      // const viewer = this.cesiumBox().getViewer()

      // // @TODO 针对横琴片区模型特殊处理视角
      // if (this.modelUrls[0] === 'https://socialize.zkyunteng.cn/3dtiles/henqin/b3dm/tileset.json') {
      //   viewer.camera.flyTo({
      //     destination: Cesium.Cartesian3.fromDegrees(113.50198245243624, 22.13985520176371, 13000),
      //     duration: 1.5
      //   })
      // } else {
      //   viewer.zoomTo(tilesetData)
      // }

      // 飞过去
      const boundingSphere = Cesium.BoundingSphere.fromPoints(this.pointPositions)
      window.viewerData.camera.flyToBoundingSphere(boundingSphere)
    },

    /**
     * 获取当前使用者的实时位置并渲染在地图位置上
     */
    locateCurrentLocation() {
      const entityGenerate = this.cesiumBox().getEntity()

      AMap.plugin('AMap.Geolocation', () => {
        const geolocation = new AMap.Geolocation({
          showButton: false
        })

        geolocation.getCurrentPosition()
        AMap.event.addListener(
          geolocation,
          'complete',
          (res) => {
            const p = res.position
            const { lng, lat } = coordinateTransform.gcj02Wg84(p.lng, p.lat)

            entityGenerate.textLabel(Date.now(), Cesium.Cartesian3.fromDegrees(lng, lat, 10), {
              text: '你的位置',
              pointIcon: require('@/assets/images/model/icon-location.png'),
              distanceDisplayCondition: [0, Number.POSITIVE_INFINITY]
            })

            this.cesiumBox().handlerFlyTo(Cesium.Cartesian3.fromDegrees(lng, lat, 500))
          },
          (error) => {
            console.error(error)
          }
        )
      })
    },

    resetDrawData() {
      try {
        const viewer = this.cesiumBox().getViewer()
        if (this.currentDataSource) {
          viewer.dataSources.remove(this.currentDataSource, true)
        }
        if (this.entityLabels && this.entityLabels.length > 0) {
          this.entityLabels.forEach(item => viewer.entities.remove(item))
        }
        this.entityLabels = []
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * 根据公司列表, 在地图渲染对应的icon
     * @param {Array} data
     * @param {Boolean} isSearch
     */
    handlerDrawCompanyPoint(data, isSearch) {
      this.resetDrawData()
      // 记录点的位置，用于跳转
      this.pointPositions = []

      const entityCollection = new Cesium.CustomDataSource('companyLists')

      data.forEach((item, index) => {
        const { lng, lat } = coordinateTransform.gcj02Wg84(item.longitude, item.latitude)
        item.lng = lng
        item.lat = lat

        try {
          const label = this.formatPointData(item, index, entityCollection)
          this.entityLabels.push(label)
        } catch (error) {
          console.log(error)
        }
      })

      this.currentDataSource = entityCollection
      this.setPointIcps(entityCollection)

      // 飞过去
      this.flyToInitCamera()

      this.companyList = data

      // 搜索状态并且搜索结果只有一个, 飞向目标
      if (isSearch && data.length === 1) {
        this.fetchRangeGetPerspective(this.companyList[0])
      }
    },

    // 渲染图标
    formatPointData(data, index, entityCollection) {
      const viewer = this.cesiumBox().getViewer()

      const { lng, lat } = coordinateTransform.gcj02Wg84(data.longitude, data.latitude)
      data.lng = lng
      data.lat = lat

      try {
        const _linefe = (text) => {
          let i = 0
          return text
            .split('')
            .reduce((pre, cur) => {
              const content = pre + cur
              if (i === 5) {
                i = 0
                return content + '\n'
              }
              i++
              return content
            }, '')
            .trim()
        }

        const position = Cesium.Cartesian3.fromDegrees(lng, lat, 10)

        this.pointPositions.push(position)

        let type = BUILD_TYPE_BY_NAME[data.buildType || '其他']

        type = type === void 0 ? BUILD_TYPE_BY_NAME['其他'] : type

        entityCollection.entities.add({
          objId: `company-${index}`,
          shapeType: 'Label',
          position,
          billboard: {
            image: require(`@/assets/images/model/build/build-icon_${type}.png`),
            width: 30,
            height: 40,
            disableDepthTestDistance: Number.POSITIVE_INFINITY
          }
        })

        return viewer.entities.add({
          position,
          label: {
            show: true,
            text: _linefe(data.name),
            font: '12px Microsoft YaHei',
            fillColor: Cesium.Color.fromCssColorString('#fff').withAlpha(1),
            outlineWidth: 0,
            showBackground: true,
            style: Cesium.LabelStyle.FILL,
            pixelOffset: new Cesium.Cartesian2(0, -40),
            backgroundColor: Cesium.Color.fromCssColorString('#d50000').withAlpha(1),
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0, 3000),

            disableDepthTestDistance: Number.POSITIVE_INFINITY
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    generateAggregateIcon(text) {
      const size = 56
      const canvas = document.createElement('canvas')
      canvas.width = size
      canvas.height = size
      const center = size / 2

      const ctx = canvas.getContext('2d')

      // 外圆
      ctx.beginPath()
      ctx.arc(center, center, 25, 0, 2 * Math.PI, false)
      ctx.fillStyle = '#ed7c7c'
      ctx.fill()
      ctx.closePath()

      // 内圆
      ctx.beginPath()
      ctx.arc(center, center, 20, 0, 2 * Math.PI, false)
      ctx.fillStyle = '#e22d2d'
      ctx.fill()
      ctx.closePath()

      // 文案
      ctx.fillStyle = '#fff'
      ctx.font = '13px bold 黑体'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(text, center, center)

      return canvas.toDataURL('image/png')
    },

    // 设置图标的聚合
    setPointIcps(entityCollection) {
      const viewer = this.cesiumBox().getViewer()
      this.dataSourcesPromise = viewer.dataSources.add(entityCollection)

      this.dataSourcesPromise.then((dataSource) => {
        dataSource.clustering.enabled = true
        dataSource.clustering.pixelRange = 10
        dataSource.clustering.minimumClusterSize = 2
        dataSource.clustering.clusterBillboards = true
        dataSource.clustering.clusterLabels = true

        dataSource.clustering.clusterEvent.addEventListener((clusteredEntities, cluster) => {
          cluster.label.show = false
          cluster.billboard.show = true
          cluster.billboard.id = cluster.label.id
          cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM

          cluster.billboard.image = this.generateAggregateIcon(clusteredEntities.length)
        })

        const pixelRange = dataSource.clustering.pixelRange
        dataSource.clustering.pixelRange = 0
        dataSource.clustering.pixelRange = pixelRange
      })
    },

    fetchRangeGetPerspective(data) {
      const { lng, lat, id } = data
      const viewer = this.cesiumBox().getViewer()

      return api
        .rangeGet({
          unitId: id
        })
        .then((data) => {
          if (!this.tiletVisible) {
            if (+data.id === 0) {
              viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(lng, lat, MAX_HEIGHT - 1000)
              })
            } else {
              this.handlerFlyToDefault(data)
            }
            this.tiletVisible = true
            tilesets.forEach(item => item.show = true)
          }

          return data
        })
    },

    handlerFlyToDefault(data) {
      const viewer = this.cesiumBox().getViewer()
      const { position: p, heading, pitch, roll } = JSON.parse(data.config)

      viewer.camera.flyTo({
        destination: new Cesium.Cartesian3(p.x, p.y, p.z),
        orientation: {
          heading,
          pitch,
          roll
        }
      })
    }
  }
}
