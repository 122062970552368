<template>
  <div class="deduction-container">
    <transition name="el-fade-in-linear">
      <router-view></router-view>
    </transition>

    <!-- 内景优诺 -->
    <t-d-contain v-show="!hide3dInside" />
    <!-- 外部实景 -->
    <cesium-box
      v-if="showCesiumBox"
      v-show="!hideCesiumBox && !enterInside"
      ref="cesiumBox"
      @reset="handlerDrawCancel"
      @success="handlerDrawSuccess"
      @del-entity="handlerDeleteEntity"
      @enter-internal="onEnterInternal"
    >
    </cesium-box>

    <!-- 返回室外 -->
    <div v-if="enterInside" v-show="!hide3dInside" class="switch-scene-btn" @click="changeSceneStatus(false)">
      <p>返回</p>
      <p>室外</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TDContain from '@/components/TDContain'
import CesiumBox from '@/components/ModelComponents/CesiumBox/index'

export default {
  components: {
    TDContain,
    CesiumBox
  },

  provide() {
    return {
      cesiumBox: this.cesiumBox,
      isInside: this.isInside
    }
  },

  data() {
    return {
      showCesiumBox: true,
      hideCesiumBox: false,

      hide3dInside: false
    }
  },

  created() {
    this.$event = [
      ['hide.operate.inside.status', status => this.hide3dInside = status],
      ['hide.operate.cesiumbox.status', status => this.hideCesiumBox = status]
    ]

    this.$event.forEach(item => this.$EventBus.$on(...item))
  },

  beforeDestroy() {
    this.$event.forEach(item => this.$EventBus.$off(...item))
  },

  computed: {
    ...mapState('planDrill', ['enterInside'])
  },

  methods: {
    cesiumBox() {
      return this.$refs.cesiumBox
    },

    handlerDrawCancel() {
      this.$EventBus.$emit('draw.cancel.callback.outside')
    },

    handlerDrawSuccess(data) {
      this.$EventBus.$emit('draw.success.callback.outside', data)
    },

    handlerDeleteEntity(data) {
      this.$EventBus.$emit('draw.delete.callback.outside', data)
    },

    onEnterInternal(data) {
      this.$store.dispatch('planDrill/setThingModel', data)
      // @TODO: 这里进去的是默认第一个建筑体, 没有考虑多个建筑体的情况
      this.changeSceneStatus(true)
    },

    changeSceneStatus(isInside = false) {
      this.$store.dispatch('planDrill/changeSceneStatus', isInside)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/#cesiumContainerBox {
  width: 100vw;
}

.deduction-container {
  &::v-deep .iframe-box,
  &::v-deep #cesiumContainerBox {
    position: absolute;
    left: 0;
    top: 48px;
    height: calc(100vh - 48px);
  }
}

.switch-scene-btn {
  color: #fff;
  font-size: 12px;
  width: 50px;
  height: 80px;
  text-align: center;

  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #3b5a91;

  position: absolute;
  right: 530px;
  top: 48px;
  transform: translateY(-20px);
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: translateY(0);
  }
  p:first-child {
    margin-top: 30px;
  }
}
</style>
