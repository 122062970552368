<template>
  <div>
    <top-header @history="showHistoryDeduction" />

    <div class="entrance-filter">
      <label>建筑类型：</label>
      <el-select v-model="searchData.buildId" placeholder="请选择" @change="onChangeSearch" filterable clearable>
        <el-option label="全部建筑类型" :value="null"></el-option>
        <el-option v-for="item in buildTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>

      <el-select v-model="searchData.name" placeholder="搜索重点单位" @change="onChangeSearch" filterable clearable>
        <el-option v-for="(item, index) in componyLists" :key="index" :label="item.name" :value="item.name"> </el-option>
      </el-select>

      <el-autocomplete
        clearable
        v-model="location"
        placeholder="请输入关键字搜索"
        :fetch-suggestions="searchLocationSuggestions"
        @clear="handlerDrawSearchLocation()"
        @select="handlerDrawSearchLocation"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-autocomplete>
    </div>

    <history-deduction ref="historyDeductionDialog" />
  </div>
</template>

<script>
import AMap from 'AMap'

import TopHeader from '../components/TopHeader'
import HistoryDeduction from '../components/HistoryDeduction'

import coordinateTransform from '@/utils/coordinateTransform'
import { getCompanyPage, getDimBuildTypeList } from '@/api/PlanDrill'

import cesiumMixin from './cesium'

export default {
  mixins: [cesiumMixin],

  components: {
    TopHeader,
    HistoryDeduction
  },

  data() {
    return {
      searchData: {
        buildId: '',
        name: ''
      },
      buildTypeList: [],
      componyLists: [],
      location: ''
    }
  },

  created() {
    this.fetchBuildData()
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchCompanyLists()
    })
  },

  methods: {
    // 获取单位类型
    fetchBuildData() {
      getDimBuildTypeList().then((data = []) => {
        this.buildTypeList = data
      })
    },

    // 获取全部单位, 以供选择筛选
    fetchCompanyLists() {
      return getCompanyPage({
        current: 1,
        size: 1000000,
        ...this.searchData
      }).then((data) => {
        const lists = data.records.filter((item) => {
          return item.latitude && item.longitude
        })

        this.componyLists = lists
        this.handlerDrawCompanyPoint(lists, !!this.searchData.name)
      })
    },

    onChangeSearch() {
      this.fetchCompanyLists()
      this.resetCamera()
    },

    /**
     * @description 利用高德api搜索关键词地名, 并渲染坐标
     */
    searchLocationSuggestions(queryString, cb) {
      this.handlerSearchLocationByAmap(queryString)
        .then((results) => {
          if (results.length) {
            return cb(results)
          }
          return cb([])
        })
        .catch(() => cb([]))
    },

    handlerSearchLocationByAmap(searchData) {
      return new Promise((resolve, reject) => {
        AMap.service(['AMap.PlaceSearch'], () => {
          //构造地点查询类
          const placeSearch = new AMap.PlaceSearch({
            pageSize: 20, // 单页显示结果条数
            pageIndex: 1, // 页码
            citylimit: true //是否强制限制在设置的城市内搜索
          })
          //关键字查询
          placeSearch.search(searchData, (status, result) => {
            if (status === 'complete') {
              return resolve(
                result.poiList.pois.map((item) => {
                  return {
                    value: item.name,
                    location: item.location
                  }
                })
              )
            }
            return reject()
          })
        })
      })
    },

    // 根据搜索出来的结果,在地图渲染图标
    handlerDrawSearchLocation(data) {
      if (this.searchLocationLabel) {
        this.searchLocationLabel.remove()
      }

      if (!data) return this.flyToInitCamera()

      const entityGenerate = this.cesiumBox().getEntity()

      const { value, location } = data

      // 高德坐标系转wg84
      const { lng, lat } = coordinateTransform.gcj02Wg84(location.lng, location.lat)

      this.searchLocationLabel = entityGenerate.textLabel(Date.now(), Cesium.Cartesian3.fromDegrees(lng, lat, 10), {
        text: value,
        pointIcon: require('@/assets/images/model/icon-location.png'),
        distanceDisplayCondition: [0, Number.POSITIVE_INFINITY]
      })

      this.cesiumBox().handlerFlyTo(Cesium.Cartesian3.fromDegrees(lng, lat, 500))
    },

    showHistoryDeduction() {
      this.$refs.historyDeductionDialog.toggleDialogStatus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';

.entrance-filter {
  @include flex-center();
  margin-top: 16px;
  margin-left: 16px;
  position: relative;
  z-index: 3;

  label {
    color: #fff;
    font-size: var(--zk-l-size);
  }

  .el-select,
  .el-autocomplete {
    @include size(244px, 38px);
    margin-left: 16px;
  }
}
</style>
