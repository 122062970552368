<template>
  <div>
    <top-stage ref="topStage" @change="changeGrade" />

    <right-menu ref="rightMenuCom" />
  </div>
</template>

<script>
import api from '@/api/sandTable'
import { mapState } from 'vuex'
import TopStage from './TopStage'
import RightMenu from './RightMenu'

import Common from '../../common/Common'
import CarCommon from '../../common/Car'
import { SCEN_ARIO } from '@/utils/conf'

/**
 * 部署目前没有内功部署，暂时注释掉相关代码
 */

export default {
  components: {
    TopStage,
    RightMenu
  },

  inject: ['cesiumBox', 'isInside'],

  created() {
    this.__DisasterCommon = new Common(this)
    this.__DeployCommon = new Common(this)
    this.__Car = new CarCommon(this)

    this.$$events = [
      [
        'iframe.load.complete',
        () => {
          this.fetchDisasterDataByInside()
          // this.fetchDeployDataByInside()
        }
      ],
      [
        'rotate.car.change', //改变车的旋转方向
        (angle) => {
          this.__Car.entityRotate(angle)
        }
      ]
    ]

    this.$$events.forEach(item => this.$EventBus.$on(...item))
  },

  mounted() {
    this.$nextTick(() => {
      this.initDeploy().then(() => {
        this.__DisasterCommon.ready()
        this.__DeployCommon.ready()
        this.__Car.ready()
      })
    })
  },

  beforeDestroy() {
    this.__DisasterCommon.clearEntity()
    this.__DisasterCommon.destroy()

    this.__DeployCommon.clearEntity()
    this.__DeployCommon.destroy()

    this.__Car.clearEntity()
    this.__Car.destroy()
    this.$$events.forEach(item => this.$EventBus.$off(...item))
  },

  computed: {
    ...mapState('planDrill', ['moduleBuildId']),
    sandTableId() {
      return this.$route.query.sandTableId
    }
  },

  methods: {
    /**
     * 初始化:
     * 加载外景火灾信息（仅查看），内景的火灾数据，load成功回调后加载
     * 加载外景旧的部署记录，内景同上
     * 初始化右侧菜单栏（作战力量/作战装备/作战指令）
     */
    initDeploy() {
      return this.fetchDisasterDataByOutside().then(() => this.fetchDeployDataByOutside())
    },

    /**
     * 加载灾情数据 Start
     */
    fetchDisasterDataByOutside() {
      return this.fetchDisasterCommon().then((data) => {
        // 仅查看
        data.forEach(item => item.isView = true)
        this.__DisasterCommon.outside.clearEntity()
        this.__DisasterCommon.outside.renderDrawData(data)
      })
    },

    // 内景等场景加载完毕再请求（兼容多建筑体）
    fetchDisasterDataByInside() {
      if (this.requireInsideTimer) {
        clearTimeout(this.requireInsideTimer)
      }

      this.requireInsideTimer = setTimeout(() => {
        clearTimeout(this.requireInsideTimer)
        this.requireInsideTimer = null

        const currentBuildId = this.moduleBuildId

        this.fetchDisasterCommon(currentBuildId).then((data) => {
          this.__DisasterCommon.inside.clearEntity()
          this.__DisasterCommon.inside.renderDrawData(data)
        })
      }, 1200)
    },

    fetchDisasterCommon(buildId) {
      const data = {
        sandTableId: this.sandTableId,
        status: 0,
        scene: SCEN_ARIO.EXTERNAL
      }

      if (buildId) {
        data.buildId = buildId
        data.scene = SCEN_ARIO.INTERNAL
      }

      return api.disaster.get(data)
    },

    /**
     * 加载部署数据 Start
     */
    fetchDeployDataByOutside() {
      return this.fetchDeployCommon().then((data) => {
        this.__Car.clearEntity()
        this.__DeployCommon.outside.clearEntity()
        data.forEach((item) => {
          if (item.carId > 0) {
            this.__Car.renderDrawDataItem(item)
          } else {
            this.__DeployCommon.outside.renderDrawDataItem(item)
          }
        })
      })
    },

    // 内景等场景加载完毕再请求（兼容多建筑体）
    // fetchDeployDataByInside() {
    //   if (this.requireInsideTimer) {
    //     clearTimeout(this.requireInsideTimer)
    //   }

    //   this.requireInsideTimer = setTimeout(() => {
    //     clearTimeout(this.requireInsideTimer)
    //     this.requireInsideTimer = null

    //     const currentBuildId = this.moduleBuildId

    //     this.fetchDeployCommon(currentBuildId).then((data) => {
    //       this.__DeployCommon.inside.clearEntity()
    //       this.__DeployCommon.inside.renderDrawData(data)
    //     })
    //   }, 1200)
    // },

    fetchDeployCommon(buildId, grade) {
      grade = grade || this.$refs.topStage.currentGrade || 1

      const data = {
        sandTableId: this.sandTableId,
        status: 0,
        scene: SCEN_ARIO.EXTERNAL,
        grade
      }

      if (buildId) {
        data.buildId = buildId
        data.scene = SCEN_ARIO.INTERNAL
      }

      return api.deploy.get(data)
    },

    /**
     * 切换阶段
     * @description
     * 1、之前的阶段
     * 外景，遍历数据对比grade，清除对应数据
     * 内景，遍历数据对比grade，先清空，后将过滤后的数据重新渲染
     *
     * 2、往后的阶段
     * 外景，对比grade，遍历请求数据，叠加渲染
     * 内景，对比grade，遍历请求数据，清空，后重新渲染
     */
    changeGrade({ grade, lastCurrentGrade }) {
      if (grade < lastCurrentGrade) {
        this.__Car.removeDataByGrade(grade)
        this.__DeployCommon.removeDataByGrade(grade)
      } else {
        this.fetchDrawDataByGrade(grade, lastCurrentGrade)
      }
    },

    // count -跳跃的阶段数目
    fetchDrawDataByGrade(grade, lastCurrentGrade) {
      for (let i = lastCurrentGrade + 1; i <= grade; i++) {
        this.fetchDeployCommon(null, i).then((data) => {
          data.forEach((item) => {
            if (item.carId > 0) {
              this.__Car.renderDrawDataItem(item)
            } else {
              this.__DeployCommon.outside.renderDrawDataItem(item)
            }
          })
        })

        // 请求当前内景阶段数据
        // this.fetchDeployCommon(this.moduleBuildId)
        //   .then((data) => {
        //     this.__DeployCommon.inside.renderDrawData(data)
        //   })
      }
    },

    requestRemoveEntity(id) {
      return api.deploy.delete({
        id
      })
    },

    popupConfirm(data) {
      this.$refs.rightMenuCom.popupConfirm(data)
    },

    popupCarModel(data) {
      this.$refs.rightMenuCom.popupCarModel(data)
    },

    changeCarPostion({ data, position }) {
      const params = data.data

      api.deploy
        .add({
          id: params.id,
          carId: params.carId,
          grade: params.grade,
          latitude: params.latitude,
          longitude: params.longitude,
          sandTableId: params.sandTableId,
          description: params.description,
          position: JSON.stringify(position),
          operationJson: params.operationJson
        })
        .then(() => {
          this.$message.success('自动保存成功')
        })
    },

    startDraw(data) {
      if (data.carId) {
        return this.handlerDrawCar(data)
      }

      this.__DeployCommon.startDraw(data)
    },

    handlerDrawCar(data) {
      this.__Car.startDraw(data)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
</style>
