<template>
  <header class="header">
    <div class="left" @click="saveDraft">
      <svg t="1629183388113" class="icon" viewBox="0 0 1303 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2725" width="16" height="16">
        <path
          d="M884.363636 186.181818H176.872727L279.272727 83.781818c18.618182-18.618182 18.618182-46.545455 0-65.163636s-46.545455-18.618182-65.163636 0L46.545455 186.181818c-27.927273 27.927273-27.927273 74.472727 0 102.4l167.563636 167.563637c9.309091 9.309091 18.618182 9.309091 37.236364 9.30909s18.618182 0 27.927272-9.30909c18.618182-18.618182 18.618182-46.545455 0-65.163637L176.872727 279.272727h707.490909C1061.236364 279.272727 1210.181818 428.218182 1210.181818 605.090909S1061.236364 930.909091 884.363636 930.909091h-651.636363c-27.927273 0-46.545455 18.618182-46.545455 46.545454s18.618182 46.545455 46.545455 46.545455h651.636363C1117.090909 1024 1303.272727 837.818182 1303.272727 605.090909v-18.618182C1293.963636 363.054545 1107.781818 186.181818 884.363636 186.181818z"
          fill="#18CFFD"
          p-id="2726"
        ></path>
      </svg>
      <span>返回</span>
    </div>

    <el-button type="zk-min-pimary" @click="isShownWhiteboardRecord = !isShownWhiteboardRecord">标绘记录</el-button>

    <!-- <el-button type="text" @click="saveDraft" style="margin-left: 80px">
      <img src="@/assets/images/planDrill/un-save.png" alt="un-save" />
      <span>不保存退出</span>
    </el-button> -->
    <el-button type="text" @click="saveOfficial" style="margin-left: 35px">
      <img src="@/assets/images/planDrill/save.png" alt="save" />
      <span>保存退出</span>
    </el-button>

    <whiteboard-record v-if="isShownWhiteboardRecord" />
  </header>
</template>

<script>
import HtmlHostMsg from 'HtmlHostMsg'

import api from '@/api/sandTable'
import { uploadFile } from '@/api/upload'
import { dataURLtoFile, uploadPreprocess } from '@/utils/util'

import WhiteboardRecord from '../WhiteboardRecord/index'

export default {
  components: {
    WhiteboardRecord
  },

  data() {
    return {
      isShownWhiteboardRecord: false
    }
  },

  inject: ['cesiumBox'],

  computed: {
    sandTableId() {
      return this.$route.query.sandTableId
    }
  },

  watch: {
    // 内景截图成功回调, 异步保存截图
    '$store.state.planDrill.screenUnitDraw': {
      handler(val) {
        this.$nextTick(() => {
          this.changeCoverUrlCommon(val.dataUrl)
        })
      }
    }
  },

  methods: {
    goHomeBaby() {
      this.$router.replace({ path: '/deduction/entrance' })
    },

    saveDraft() {
      this.$confirm('是否确定不保存刚新增的数据，并退出推演？', '提示').then(() => {
        const loading = this.$loading({
          lock: true,
          text: '处理中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        const id = this.sandTableId

        this.goHomeBaby()

        api.record
          .saveDraft({ id })
          .then(() => {
            setTimeout(() => {
              loading.close()
            }, 1500)
          })
          .catch(() => loading.close())
      })
    },

    saveOfficial() {
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      // 异步保存截图
      this.changeCoverUrl()

      return api.record
        .saveOfficial({
          id: this.sandTableId
        })
        .then(() => {
          setTimeout(() => {
            loading.close()
            this.$message.success('保存成功')
            this.goHomeBaby()
          }, 1500)
        })
    },

    changeCoverUrl() {
      if (this.$store.state.planDrill.enterInside) {
        HtmlHostMsg.event('screen', {})
      } else {
        this.changeCoverUrlOutside()
      }
    },

    // 修改封面图
    changeCoverUrlOutside() {
      return this.cesiumBox()
        .captureScreenshot(0.4)
        .then(base64 => this.changeCoverUrlCommon(base64))
    },

    changeCoverUrlCommon(base64) {
      // 生成md5，base64转file
      uploadPreprocess(dataURLtoFile(base64))
        // 上传
        .then(params => uploadFile(params))
        .then((url) => {
          return api.record.add({
            coverUrl: url,
            unitId: this.$route.query.id,
            id: this.sandTableId
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins.scss';

.header {
  @include size(100vw, 48px);
  @include flex-center();

  padding: 0 16px;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(6, 19, 38, 0.53), rgba(17, 49, 94, 0.53));
  position: relative;
  z-index: 4;

  &::before {
    content: '';
    @include size(100%);
    background-color: #061121;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .left {
    @include flex-center();
    color: var(--zk-text);
    cursor: pointer;
    span {
      margin-left: 5px;
    }
  }

  .el-button--zk-min-pimary {
    margin-left: auto;
  }
}

.el-button--text {
  display: flex;
  img {
    margin-right: 6px;
  }
}

.el-button--zk-min-pimary {
  border-radius: 0;
  border-color: var(--zk-color);
  background-color: transparent;

  color: var(--zk-text);
  font-size: var(--zk-min-size);
  padding: 7px 8px;
  box-shadow: inset 0px 0px 11px 2px #18cffd96;
  transition: 0.25s;

  &:hover,
  &:focus {
    transform: translateY(-0.15em);
  }
}
</style>
