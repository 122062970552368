<template>
  <div>
    <fire-message />

    <ul class="disater-menu menu-box">
      <li @click="selectMenu">
        <img src="@/assets/images/planDrill/fire.png" alt="fire" />
        <span>{{ MENU_DATA.name }}</span>
      </li>
    </ul>

    <transition name="el-fade-in-linear">
      <zk-dialog v-if="isShownDescription" ref="dialog" title="灾情描述" width="470px" @close="onCloseDescription" @submit="onSubmit">
        <div class="disater-description">
          <div v-for="item in describeTip" :key="item.key">
            <label>{{ item.label }}</label>
            <ul>
              <li v-for="option in item.options" :key="option" :class="{ active: tipSelected[item.key] === option }" @click="selectTip(item.key, option)">{{ option }}</li>
            </ul>
          </div>

          <div>
            <label>描述</label>
            <el-input v-model="description" type="textarea" placeholder="请输入描述" :autosize="{ minRows: 3, maxRows: 3 }"></el-input>
          </div>
        </div>
      </zk-dialog>
    </transition>

    <!-- 操作界面 -->
    <operation-panel ref="operationPanel" @delete="requestRemoveEntityInside" @update="updateInsideDrawData"></operation-panel>
  </div>
</template>

<script>
import api from '@/api/sandTable'
import { mapState } from 'vuex'
import describeTip from './describeTip'

import HtmlHostMsg from 'HtmlHostMsg'

import Common from '../../common/Common'
import { SCEN_ARIO } from '@/utils/conf'

import OperationPanel from '../OperationPanel'
import FireMessage from './components/FireMessage'

/**
 * 此处写死灾情部署菜单
 */
const MENU_DATA = {
  id: 1,
  taskId: 1,
  key: 'fire',
  name: '点状火'
}

export default {
  components: {
    OperationPanel,
    FireMessage
  },

  data() {
    return {
      MENU_DATA,
      describeTip,
      isShownDescription: false,

      description: '',
      tipSelected: {}
    }
  },

  inject: ['cesiumBox', 'isInside'],

  created() {
    this.resetTipSelected()

    this.__Common = new Common(this)

    this.$$events = [['iframe.load.complete', () => this.fetchDisasterDataByInside()]]

    this.$$events.forEach(item => this.$EventBus.$on(...item))
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchDisasterDataByOutside().then(() => {
        this.__Common.ready()
      })
    })
  },

  beforeDestroy() {
    this.__Common.clearEntity()
    this.__Common.destroy()
    this.$$events.forEach(item => this.$EventBus.$off(...item))
  },

  computed: {
    ...mapState('planDrill', ['moduleBuildId']),
    sandTableId() {
      return this.$route.query.sandTableId
    }
  },

  methods: {
    // 获取所有的灾情数据，仅供显示
    // fetchAllDisaster() {
    //   api.disaster.get({
    //     sandTableId: this.sandTableId,
    //     status: 0
    //   }).then(() => {

    //   })
    // },

    // 获取已部署的灾情数据
    // 外景的
    fetchDisasterDataByOutside() {
      return this.fetchDisasterCommon().then((data) => {
        this.__Common.outside.clearEntity()
        this.__Common.outside.renderDrawData(data)
      })
    },

    // 内景等场景加载完毕再请求（兼容多建筑体）
    fetchDisasterDataByInside() {
      if (this.requireInsideTimer) {
        clearTimeout(this.requireInsideTimer)
      }

      this.requireInsideTimer = setTimeout(() => {
        clearTimeout(this.requireInsideTimer)
        this.requireInsideTimer = null

        const currentBuildId = this.moduleBuildId
        console.log(currentBuildId)

        this.fetchDisasterCommon(currentBuildId).then((data) => {
          this.__Common.inside.clearEntity()
          this.__Common.inside.renderDrawData(data)
        })
      }, 1200)
    },

    fetchDisasterCommon(buildId) {
      const data = {
        sandTableId: this.sandTableId,
        status: 0,
        scene: SCEN_ARIO.EXTERNAL
      }

      if (buildId) {
        data.buildId = buildId
        data.scene = SCEN_ARIO.INTERNAL
      }

      return api.disaster.get(data)
    },

    // 点击菜单开始绘制
    selectMenu() {
      this.__Common.startDraw(MENU_DATA)
    },

    // 绘制结束，弹出弹窗最终确认
    popupConfirm(data) {
      this.lastSuccessData = data
      if (data.location) {
        this.description = `地址：${data.location}`
      }
      this.isShownDescription = true
    },

    // 充值提示信息选择
    resetTipSelected() {
      describeTip.forEach(({ key }) => this.$set(this.tipSelected, key, ''))
    },

    // 选择提示信息
    selectTip(key, option) {
      console.log(key, option)
      if (option === this.tipSelected[key]) {
        return this.tipSelected[key] = ''
      }
      this.tipSelected[key] = option
    },

    // 关闭弹窗
    onCloseDescription() {
      this.isShownDescription = false
      this.resetTipSelected()

      if (this.$store.state.planDrill.enterInside) {
        HtmlHostMsg.event('tool', { clear: true, draw: true })
      }
    },

    // 最终确认
    onSubmit() {
      const isInsde = this.$store.state.planDrill.enterInside

      const { latitude, longitude, positions, ...otherData } = this.lastSuccessData
      this.$refs.dialog.showLoading()

      let data = {
        sandTableId: this.sandTableId,
        deployTypeId: MENU_DATA.id,
        description: this.description,
        latitude,
        longitude,
        // 额外选的参数
        param: JSON.stringify({
          describeTip: this.tipSelected
        }),
        // 坐标点
        position: JSON.stringify(positions)
      }

      if (isInsde) {
        data = {
          ...data,
          ...otherData,
          buildId: this.moduleBuildId
        }
      }

      api.disaster
        .add(data)
        .then((data) => {
          this.$message.success('设置成功')
          this.onCloseDescription()

          if (isInsde) {
            this.fetchDisasterDataByInside()
          } else {
            this.__Common.outside.renderDrawDataItem(data)
          }
        })
        .finally(() => {
          this.$refs.dialog && this.$refs.dialog.hideLoading()
        })
    },

    requestRemoveEntity(id) {
      return api.disaster.delete({
        id
      })
    },

    requestRemoveEntityInside(data) {
      const { id, key } = data

      this.requestRemoveEntity(id).then(() => {
        this.$message.success('删除成功')
        HtmlHostMsg.event('tool', { id, key, remove: true })
        this.fetchDisasterDataByInside()
      })
    },

    updateInsideDrawData(toolData) {
      let { currentBuildId, config, points, position } = toolData

      if (config?.create?.config) delete toolData.config.create.config

      api.disaster
        .add({
          ...toolData,
          position: position || points,
          buildId: currentBuildId || this.moduleBuildId,
          deployTypeId: MENU_DATA.id
        })
        .then(() => {
          this.$message.success('保存成功')
          this.fetchDisasterDataByInside()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/mixins';
@import '../common';

.disater-menu {
  @include size(68px, calc(100vh - 48px));
  right: 49px;
  padding-top: 21px;
  box-sizing: border-box;

  li {
    @include flex-center(center, column);
    cursor: pointer;
    color: #fff;
    img {
      @include size(30px);
      margin-bottom: 5px;
    }
  }
}

.disater-description {
  & > div {
    display: flex;
    margin-bottom: 20px;
    label {
      flex: 0 0 60px;
      margin-right: 22px;
      font-weight: 700;
      color: #fff;
      text-align: right;
      margin-top: 8px;
    }

    ul {
      @include flex-center();
      flex-wrap: wrap;
      li {
        color: #fff;
        padding: 6px 10px;
        min-height: 22px;
        line-height: 22px;
        border-radius: 2px;
        font-size: var(--zk-size);
        background-color: rgba(76, 89, 108, 0.63);
        margin-bottom: 8px;
        user-select: none;
        cursor: pointer;
        transition: all 0.2s;
        &:not(:last-child) {
          margin-right: 8px;
        }
        &.active {
          background-color: var(--zk-color);
        }
      }
    }

    &::v-deep .el-textarea__inner {
      padding: 10px 15px;
    }
  }
}
</style>
